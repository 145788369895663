.style-secondary {
  align-items: center;
  border: 1px solid;
  border-color: var(--primitive-color-brand-espresso);
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  padding: 12px 24px;
  position: relative; 
  border-radius: 10px;
  background-color: var(--primitive-color-brand-white);
}

.style-secondary .button-2 {
  color: var(--semantic-text-primary);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
