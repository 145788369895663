.home-mobile {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 393px;
  background-color: var(--semantic-background-color-primary);
}

.home-mobile .header-no-text {
  align-items: flex-start;
  background-color: var(--primitive-color-brand-champagne-duplicate);
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 393px;
  
}

.home-mobile .header {
  align-items: center;
  align-self: stretch;
  background-color: var(--primitive-color-brand-champagne-duplicate);
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 0px 0px 0px 10px;
  position: relative;
  width: 393px;
  box-sizing: border-box;
}

.home-mobile .icon-menu {
  height: 51px !important;
  position: relative !important;
  width: 51px !important;
  
}

.home-mobile .masthead {
  height: 81px;
  position: relative;
  width: 320px;
}

.home-mobile .hero {
  align-items: flex-start;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 36px 20px 45px;
  position: relative;
  width: 393px;
  box-sizing: border-box;
}

.home-mobile .content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.home-mobile .column {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.home-mobile .medium-length-hero {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h1-font-family);
  font-size: var(--heading-mobile-h1-font-size);
  font-style: var(--heading-mobile-h1-font-style);
  font-weight: var(--heading-mobile-h1-font-weight);
  letter-spacing: var(--heading-mobile-h1-letter-spacing);
  line-height: var(--heading-mobile-h1-line-height);
  margin-top: -1.00px;
  position: relative;
}

.home-mobile .div {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.home-mobile .heading-1 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-large-normal-font-family);
  font-size: var(--text-large-normal-font-size);
  font-style: var(--text-large-normal-font-style);
  font-weight: var(--text-large-normal-font-weight);
  letter-spacing: var(--text-large-normal-letter-spacing);
  line-height: var(--text-large-normal-line-height);
  margin-top: -1.00px;
  position: relative;
}

.home-mobile .text-wrapper-company-name {
  font-family: var(--heading-mobile-h5-font-family);
  font-size: var(--heading-mobile-h5-font-size);
  font-style: var(--heading-mobile-h5-font-style);
  font-weight: var(--heading-mobile-h5-font-weight);
  letter-spacing: var(--heading-mobile-h5-letter-spacing);
  line-height: var(--heading-mobile-h5-line-height);
  display: block;
  margin-top: 10px;
  color: var(--semantic-text-primary);
}

.home-mobile .actions {
  align-items: center;
  display: flex;
  border-radius: 10px;
  gap: 16px;
  height: 42px;
  justify-content: center;
  position: relative;
  width: 123px;
  background-color: var(--primitive-color-brand-carrot);
}

.home-desktop .contact-wrapper {
  align-items: center;
  display: inline-flex;
  border-radius: 10px;
  flex: 0 0 auto;
  gap: 16px;
  justify-content: center;
  position: relative;
}

.home-mobile .actions {
  align-items: center;
  display: flex;
  gap: 16px;
  height: 42px;
  justify-content: center;
  position: relative;
  width: 123px;
}

.home-mobile .contact {
  background-color: var(--primitive-color-brand-carrot) !important;
  border: unset !important;
  border-color: unset !important;
  height: 40px !important;
  width: 119px !important;
  text-decoration: none !important;
}

.home-mobile .button-instance {
  color: var(--semantic-text-primary) !important;
  margin-bottom: unset !important;
  margin-left: unset !important;
  margin-right: unset !important;
  margin-top: unset !important;
}

.home-desktop .contact-2 {
  color: var(--semantic-text-primary) !important;
  margin-bottom: unset !important;
  margin-left: unset !important;
  margin-right: unset !important;
  margin-top: unset !important;
}

.home-mobile .button-instance {
  color: var(--semantic-text-primary) !important;
  margin-bottom: unset !important;
  margin-left: unset !important;
  margin-right: unset !important;
  margin-top: unset !important;
}

.home-mobile .skater {
  height: 578px;
  object-fit: contain;
  position: relative;
  width: 100%;
  max-width: 393px;
  box-sizing: border-box;
  align-self: center;
}

.home-mobile .vrtality {
  align-items: flex-start;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex-direction: column;
  gap: 48px;
  height: 823px;
  padding: 42px 20px 90px;
  position: relative;
  width: 393px;
  box-sizing: border-box;
}

.home-mobile .container {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  margin-bottom: -18.00px;
  position: relative;
  width: 100%;
}

.home-mobile .div-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.home-mobile .tagline-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.home-mobile .text-wrapper-2 {
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-tagline-font-family);
  font-size: var(--heading-desktop-tagline-font-size);
  font-style: var(--heading-desktop-tagline-font-style);
  font-weight: var(--heading-desktop-tagline-font-weight);
  letter-spacing: var(--heading-desktop-tagline-letter-spacing);
  line-height: var(--heading-desktop-tagline-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-mobile .heading {
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h2-font-family);
  font-size: var(--heading-mobile-h2-font-size);
  font-style: var(--heading-mobile-h2-font-style);
  font-weight: var(--heading-mobile-h2-font-weight);
  letter-spacing: var(--heading-mobile-h2-letter-spacing);
  line-height: var(--heading-mobile-h2-line-height);
  margin-top: -1.00px;
  position: relative;
  width: 353px;
  max-width: 393px;
}

.home-mobile .text {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
}

.home-mobile .button-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.home-mobile .design-component-instance-node {
  flex: 0 0 auto !important;
  margin-bottom: -2.00px !important;
  margin-left: -2.00px !important;
  margin-right: -2.00px !important;
  margin-top: -2.00px !important;
  overflow: hidden !important;
}

.home-mobile .button-2 {
  margin-left: -1.00px !important;
  margin-top: -1.00px !important;
  text-decoration: none !important;
}

.home-mobile .nourishing-minds {
  align-items: flex-start;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 48px 20px;
  position: relative;
  width: 393px;
  box-sizing: border-box;
}

.home-mobile .container-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  position: relative;
  width: 100%;
}

.home-mobile .heading-2 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h3-font-family);
  font-size: var(--heading-mobile-h3-font-size);
  font-style: var(--heading-mobile-h3-font-style);
  font-weight: var(--heading-mobile-h3-font-weight);
  letter-spacing: var(--heading-mobile-h3-letter-spacing);
  line-height: var(--heading-mobile-h3-line-height);
  margin-top: -1.00px;
  position: relative;
}

.home-mobile .list {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  padding: 8px 0px;
  position: relative;
  width: 100%;
}

.home-mobile .text-wrapper-3 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h6-font-family);
  font-size: var(--heading-mobile-h6-font-size);
  font-style: var(--heading-mobile-h6-font-style);
  font-weight: var(--heading-mobile-h6-font-weight);
  letter-spacing: var(--heading-mobile-h6-letter-spacing);
  line-height: var(--heading-mobile-h6-line-height);
  margin-top: -1.00px;
  position: relative;
}

.home-mobile .placeholder-image {
  align-self: stretch;
  height: 348px;
  position: relative;
  width: 100%;
}

.home-mobile .button-3 {
  flex: 0 0 auto !important;
  margin-bottom: -2.00px !important;
  margin-left: -2.00px !important;
}

.home-mobile .div-wrapper {
  align-items: flex-start;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 64px 20px;
  position: relative;
  width: 393px;
  box-sizing: border-box;
}

.home-mobile .container-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.home-mobile .actions-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}



.home-mobile .heading-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.home-mobile .p {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  margin-top: -1.00px;
  position: relative;
}

.home-mobile .footer {
  align-items: center;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 48px 20px;
  position: relative;
  width: 393px;
  box-sizing: border-box;
}

.home-mobile .content-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  position: relative;
  width: 100%;
}

.home-mobile .div-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.home-mobile .logo-instance {
  height: 36px !important;
  position: relative !important;
  width: 84px !important;
}

.home-mobile .link-one {
  align-self: stretch;
  color: var(--semantic-link-primary);
  font-family: var(--text-small-semi-bold-font-family);
  font-size: var(--text-small-semi-bold-font-size);
  font-style: var(--text-small-semi-bold-font-style);
  font-weight: var(--text-small-semi-bold-font-weight);
  letter-spacing: var(--text-small-semi-bold-letter-spacing);
  line-height: var(--text-small-semi-bold-line-height);
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}


.home-mobile .social-links {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.home-mobile .icon-instance-node {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.home-mobile .credits {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  padding: 0px 0px 16px;
  position: relative;
  width: 100%;
}

.home-mobile .divider {
  align-self: stretch;
  background-color: var(--semantic-border-primary);
  border: 1px solid;
  border-color: var(--semantic-border-primary);
  height: 1px;
  position: relative;
  width: 100%;
}

.home-mobile .row {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.home-mobile .footer-links {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.home-mobile .text-wrapper-4 {
  align-self: stretch;
  color: var(--semantic-link-primary);
  font-family: var(--text-small-link-font-family);
  font-size: var(--text-small-link-font-size);
  font-style: var(--text-small-link-font-style);
  font-weight: var(--text-small-link-font-weight);
  letter-spacing: var(--text-small-link-letter-spacing);
  line-height: var(--text-small-semi-bold-line-height);
  position: relative;
  text-align: center;
}
.home-mobile .text-wrapper-5 {
  color: var(--semantic-link-primary);
  font-family: var(--text-small-link-font-family);
  font-size: var(--text-small-link-font-size);
  font-style: var(--text-small-link-font-style);
  font-weight: var(--text-small-link-font-weight);
  letter-spacing: var(--text-small-link-letter-spacing);
  line-height: var(--text-small-link-line-height);
  margin-top: -1.00px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.home-mobile .text-wrapper-6 {
  color: var(--semantic-link-primary);
  font-family: var(--text-small-link-font-family);
  font-size: var(--text-small-link-font-size);
  font-style: var(--text-small-link-font-style);
  font-weight: var(--text-small-link-font-weight);
  letter-spacing: var(--text-small-link-letter-spacing);
  line-height: var(--text-small-link-line-height);
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.home-mobile .element-relume-all {
  color: var(--semantic-text-primary);
  font-family: var(--text-small-normal-font-family);
  font-size: var(--text-small-normal-font-size);
  font-style: var(--text-small-normal-font-style);
  font-weight: var(--text-small-normal-font-weight);
  letter-spacing: var(--text-small-normal-letter-spacing);
  line-height: var(--text-small-normal-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-mobile .vrtality-button {
  background-color: #5C191C !important;  /* Espresso color */
  color: #F7E7CE !important;  /* Champagne color */
  text-decoration: none !important;
}
