.home-desktop {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.home-desktop .navbar {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 112px;
  position: relative;
  width: 100%;
}

.home-desktop .navbar-JR-1 {
  align-self: stretch !important;
  width: 100% !important;
}

.home-desktop .icon-instance-node {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.home-desktop .hero {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex-direction: column;
  gap: 11px;
  height: 769px;
  padding: 0px 0px 1px;
  position: relative;
  width: 100%;
}

.home-desktop .content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 808px;
  margin-bottom: -40.00px;
  padding: 0px 0px 17px;
  position: relative;
  width: 100%;
}

.home-desktop .logo-heading {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 760px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.home-desktop .logo {
  height: 194.02px;
  position: relative;
}

.home-desktop .heading {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: "Roboto", Helvetica;
  font-size: 96px;
  font-weight: 700;
  height: auto;
  letter-spacing: 0;
  line-height: 115.2px;
  position: relative;
  text-align: center;
  width: 100%;
 
}

.home-desktop .span {
  color: #5c191c;
  font-family: "Roboto", Helvetica;
  font-size: 96px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 115.2px;
}


.home-desktop .text-wrapper-company-name {
  font-family: var(--heading-mobile-h3-font-family);
  font-size: var(--heading-mobile-h3-font-size);
  font-style: var(--heading-mobile-h3-font-style);
  font-weight: var(--heading-mobile-h3-font-weight);
  letter-spacing: var(--heading-mobile-h3-letter-spacing);
  line-height: var(--heading-mobile-h3-line-height);
  display: block;
  margin-top: 10px;
}

.home-desktop .institutions {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 169px;
  position: relative;
  width: 100%;
}

.home-desktop .projects-header {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.home-desktop .text-wrapper-4 {
  font-family: var(--heading-desktop-h6-font-family);
  font-size: var(--heading-desktop-h6-font-size);
  font-style: var(--heading-desktop-h6-font-style);
  font-weight: var(--heading-desktop-h6-font-weight);
  letter-spacing: var(--heading-desktop-h6-letter-spacing);
  line-height: var(--heading-desktop-h6-line-height);
  
}

.home-desktop .text-wrapper-5 {
  font-family: var(--heading-mobile-h5-font-family);
  font-size: var(--heading-mobile-h5-font-size);
  font-style: var(--heading-mobile-h5-font-style);
  font-weight: var(--heading-mobile-h5-font-weight);
  letter-spacing: var(--heading-mobile-h5-letter-spacing);
  line-height: var(--heading-mobile-h5-line-height);
  margin-bottom: 20px;
  color: var(--semantic-text-primary);
}

.home-desktop .carousel {
  align-items: center;
  align-self: stretch;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  gap: 56px;
  height: 122px;
  justify-content: center;
  overflow-x: scroll;
  position: relative;
  width: 100%;
}

.home-desktop .carousel::-webkit-scrollbar {
  display: none;
  width: 0;
}

.home-desktop .logo-UW {
  height: 64px;
  object-fit: cover;
  position: relative;
  width: 132px;
}

.home-desktop .image {
  height: 32px;
  object-fit: cover;
  position: relative;
  width: 143px;
}

.home-desktop .UNC {
  height: 72.36px;
  position: relative;
  width: 262.93px;
}

.home-desktop .logo-SPL {
  height: 100px;
  object-fit: cover;
  position: relative;
  width: 89px;
}

.home-desktop .logo-CSLA {
  height: 64px;
  object-fit: cover;
  position: relative;
  width: 240px;
}

.home-desktop .FC-logo-black-text {
  height: 85px;
  object-fit: cover;
  position: relative;
  width: 71px;
}

.home-desktop .photo-skater {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex-direction: column;
  gap: 80px;
  height: auto;
  padding: 0px 0px 36px;
  position: relative;
  width: 100%;
}

.home-desktop .skater-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-width: 100%;
}

.home-desktop .vrtality {
  align-items: center;
  align-self: stretch;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  padding: 112px 64px;
  position: relative;
  width: 100%;
}

.home-desktop .container {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 80px;
  position: relative;
  width: 100%;
}

.home-desktop .gardenvr {
  background-color: #000000;
  height: 651px;
  position: relative;
  width: 250px;
}

.home-desktop .content-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  position: relative;
}

.home-desktop .section-title {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.home-desktop .tagline-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.home-desktop .tagline {
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-tagline-font-family);
  font-size: var(--heading-desktop-tagline-font-size);
  font-style: var(--heading-desktop-tagline-font-style);
  font-weight: var(--heading-desktop-tagline-font-weight);
  letter-spacing: var(--heading-desktop-tagline-letter-spacing);
  line-height: var(--heading-desktop-tagline-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-desktop .content-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.home-desktop .p {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-h2-font-family);
  font-size: var(--heading-desktop-h2-font-size);
  font-style: var(--heading-desktop-h2-font-style);
  font-weight: var(--heading-desktop-h2-font-weight);
  letter-spacing: var(--heading-desktop-h2-letter-spacing);
  line-height: var(--heading-desktop-h2-line-height);
  margin-top: -1.00px;
  position: relative;
}

.home-desktop .text {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: "Roboto", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 27px;
  position: relative;
}

.home-desktop .actions {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.home-desktop .button-instance {
  margin-left: -1.00px !important;
  margin-top: -1.00px !important;
  text-decoration: none !important;
}

.home-desktop .button-2 {
  flex: 0 0 auto !important;
  margin-bottom: -2.00px !important;
  margin-left: -2.00px !important;
  margin-right: -2.00px !important;
  margin-top: -2.00px !important;
  overflow: hidden !important;
}

.home-desktop .nourishing-minds {
  align-items: center;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 48px 64px;
  position: relative;
  width: 100%;
  overflow: visible;
}

.home-desktop .heading-2 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: "Roboto", Helvetica;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 48.0px;
  margin-top: -1.00px;
  position: relative;
}

.home-desktop .text-2 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
}

.home-desktop .button-3 {
  flex: 0 0 auto !important;
  margin-bottom: -2.00px !important;
  margin-left: -2.00px !important;
}

.home-desktop .img {
  height: 640px;
  position: relative;
  width: 776px;
}
.home-desktop .image-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0;
  overflow: visible;
}


.home-desktop .GPT {
  align-items: center;
  align-self: stretch;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex-direction: column;
  gap: 80px;
  height: 1141px;
  padding: 112px 64px 44px;
  position: relative;
  width: 100%;
}

.home-desktop .container-2 {
  align-items: center;
  display: flex;
  gap: 69px;
  height: 985px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.home-desktop .content-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 1003px;
  margin-bottom: -9.00px;
  margin-top: -9.00px;
  position: relative;
  width: 50%;
}

.home-desktop .content-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 562px;
  position: relative;
  width: 100%;
}

.home-desktop .heading-3 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-h3-font-family);
  font-size: var(--heading-desktop-h3-font-size);
  font-style: var(--heading-desktop-h3-font-style);
  font-weight: var(--heading-desktop-h3-font-weight);
  letter-spacing: var(--heading-desktop-h3-letter-spacing);
  line-height: var(--heading-desktop-h3-line-height);
  margin-top: -1.00px;
  position: relative;
}

.home-desktop .text-3 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-medium-normal-font-family);
  font-size: var(--text-medium-normal-font-size);
  font-style: var(--text-medium-normal-font-style);
  font-weight: var(--text-medium-normal-font-weight);
  letter-spacing: var(--text-medium-normal-letter-spacing);
  line-height: var(--text-medium-normal-line-height);
  position: relative;
}

.home-desktop .text-wrapper-6 {
  color: #5c191c;
  font-family: var(--text-large-normal-font-family);
  font-size: var(--text-large-normal-font-size); 
  font-style: var(--text-large-normal-font-style);
  font-weight: var(--text-large-normal-font-weight);
  letter-spacing: var(--text-large-normal-letter-spacing);
  line-height: var(--text-large-normal-line-height);
  width: 50%;
  margin: 0 auto;
  display: block;
  text-align: justify;
}

.home-desktop .text-wrapper-7 {
  font-family: var(--text-medium-normal-font-family);
  font-size: var(--text-medium-normal-font-size);
  font-style: var(--text-medium-normal-font-style);
  font-weight: var(--text-medium-normal-font-weight);
  letter-spacing: var(--text-medium-normal-letter-spacing);
  line-height: var(--text-medium-normal-line-height);
  text-decoration: underline;
}

.home-desktop .placeholder-image-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 290px;
  margin-right: -4.00px;
  position: relative;
  width: 608px;
}

.home-desktop .placeholder-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  min-width: 0;
  max-width: 100%;
}

.home-desktop .button-4 {
  flex: 0 0 auto !important;
  margin-left: -2.00px !important;
}

.home-desktop .BEBS-concept-map {
  height: 843px;
  object-fit: cover;
  position: relative;
  width: 603px;
}

.home-desktop .div-wrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  padding: 112px 64px;
  position: relative;
  width: 100%;
}

.home-desktop .div-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
  width: 100%;
}

.home-desktop .div-3 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  position: relative;
}

.home-desktop .row {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 768px;
}

.home-desktop .contact-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  justify-content: center;
  position: relative;
}

.home-desktop .contact {
  background-color: var(--primitive-color-brand-carrot) !important;
  border: unset !important;
  border-color: unset !important;
  height: 40px !important;
  width: 119px !important;
}

.home-desktop .contact-2 {
  color: var(--semantic-text-primary) !important;
  margin-bottom: unset !important;
  margin-left: unset !important;
  margin-right: unset !important;
  margin-top: unset !important;
}

.home-desktop .content-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 80px;
  position: relative;
  width: 100%;
}

.home-desktop .column {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  position: relative;
}

.home-desktop .text-4 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: "Roboto", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 27px;
  margin-top: -1.00px;
  position: relative;
}

.home-desktop .footer {
  align-items: center;
  align-self: stretch;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  padding: 80px 64px;
  position: relative;
  width: 100%;
}

.home-desktop .company-logo {
  height: 36px !important;
  position: relative !important;
  width: 84px !important;
}

.home-desktop .links {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.home-desktop .text-wrapper-8 {
  color: var(--semantic-link-primary);
  font-family: var(--text-small-semi-bold-font-family);
  font-size: var(--text-small-semi-bold-font-size);
  font-style: var(--text-small-semi-bold-font-style);
  font-weight: var(--text-small-semi-bold-font-weight);
  letter-spacing: var(--text-small-semi-bold-letter-spacing);
  line-height: var(--text-small-semi-bold-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-desktop .text-wrapper-8 a {
  color: var(--primitive-color-brand-parsley) !important;
  text-decoration: none;
  font-family: var(--text-small-semi-bold-font-family);
  font-size: var(--text-small-semi-bold-font-size);
  font-style: var(--text-small-semi-bold-font-style);
  font-weight: var(--text-small-semi-bold-font-weight);
  letter-spacing: var(--text-small-semi-bold-letter-spacing);
  line-height: var(--text-small-semi-bold-line-height);
}

.home-desktop .text-wrapper-8 a:hover {
  text-decoration: underline;  /* Optional: adds underline on hover */
}

.home-desktop .social-links {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 12px;
  justify-content: flex-end;
  position: relative;
}

.home-desktop .credits {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.home-desktop .divider {
  align-self: stretch;
  background-color: var(--semantic-border-primary);
  border: 1px solid;
  border-color: var(--semantic-border-primary);
  height: 1px;
  position: relative;
  width: 100%;
}

.home-desktop .row-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.home-desktop .text-wrapper-9 {
  color: var(--semantic-text-primary);
  font-family: var(--text-small-normal-font-family);
  font-size: var(--text-small-normal-font-size);
  font-style: var(--text-small-normal-font-style);
  font-weight: var(--text-small-normal-font-weight);
  letter-spacing: var(--text-small-normal-letter-spacing);
  line-height: var(--text-small-normal-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-desktop .text-wrapper-10 {
  color: var(--semantic-link-primary);
  font-family: var(--text-small-link-font-family);
  font-size: var(--text-small-link-font-size);
  font-style: var(--text-small-link-font-style);
  font-weight: var(--text-small-link-font-weight);
  letter-spacing: var(--text-small-link-letter-spacing);
  line-height: var(--text-small-link-line-height);
  margin-top: -1.00px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}
