.publications-desktop {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.publications-desktop .navbar {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.publications-desktop .navbar-JR-1 {
  align-self: stretch !important;
  width: 100% !important;
}

.publications-desktop .icon-instance-node {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.publications-desktop .main-section {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.publications-desktop .articles {
  align-items: center;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex-direction: column;
  gap: 3px;
  height: 760px;
  padding: 55px 246px 0px 183px;
  position: relative;
  width: 50%;
}

.publications-desktop .conferences {
  align-items: center;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex-direction: column;
  gap: 3px;
  height: 760px;
  padding: 55px 246px 0px 129px;
  position: relative;
  width: 50%;
}

.publications-desktop .main-title {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: "Ofelia Text-Light", Helvetica;
  font-size: 50px;
  font-weight: 300;
  height: 194px;
  letter-spacing: -0.50px;
  line-height: 60.0px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.publications-desktop .article {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 4px;
  padding: 15px 0px;
  position: relative;
  width: 100%;
}

.publications-desktop .article-icon {
  height: 18px !important;
  position: relative !important;
  width: 18px !important;
}

.publications-desktop .title {
  color: var(--semantic-link-primary);
  font-family: var(--text-large-light-font-family);
  font-size: var(--text-large-light-font-size);
  font-style: var(--text-large-light-font-style);
  font-weight: var(--text-large-light-font-weight);
  letter-spacing: var(--text-large-light-letter-spacing);
  line-height: var(--text-large-light-line-height);
  margin-right: -215.00px;
  margin-top: -1.00px;
  position: relative;
  text-decoration: underline;
  width: 643px;
}

.publications-desktop .p {
  color: var(--semantic-link-primary);
  font-family: var(--text-large-light-font-family);
  font-size: var(--text-large-light-font-size);
  font-style: var(--text-large-light-font-style);
  font-weight: var(--text-large-light-font-weight);
  letter-spacing: var(--text-large-light-letter-spacing);
  line-height: var(--text-large-light-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.publications-desktop .article-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 4px;
  height: 97px;
  padding: 15px 0px;
  position: relative;
  width: 100%;
}

.publications-desktop .link-text-3 {
  color: var(--semantic-link-primary);
  font-family: var(--text-large-light-font-family);
  font-size: var(--text-large-light-font-size);
  font-style: var(--text-large-light-font-style);
  font-weight: var(--text-large-light-font-weight);
  letter-spacing: var(--text-large-light-letter-spacing);
  line-height: var(--text-large-light-line-height);
  margin-right: -155.00px;
  margin-top: -1.00px;
  position: relative;
  width: 583px;
}

.publications-desktop .link-text-4 {
  color: var(--semantic-link-primary);
  font-family: var(--text-large-light-font-family);
  font-size: var(--text-large-light-font-size);
  font-style: var(--text-large-light-font-style);
  font-weight: var(--text-large-light-font-weight);
  letter-spacing: var(--text-large-light-letter-spacing);
  line-height: var(--text-large-light-line-height);
  margin-top: -1.00px;
  position: relative;
  width: 302px;
}

.publications-desktop .link-text-5 {
  color: var(--semantic-link-primary);
  font-family: var(--text-large-light-font-family);
  font-size: var(--text-large-light-font-size);
  font-style: var(--text-large-light-font-style);
  font-weight: var(--text-large-light-font-weight);
  letter-spacing: var(--text-large-light-letter-spacing);
  line-height: var(--text-large-light-line-height);
  margin-top: -1.00px;
  position: relative;
  width: 306px;
}



.publications-desktop .title-2 {
  color: var(--semantic-link-primary);
  font-family: var(--text-large-light-font-family);
  font-size: var(--text-large-light-font-size);
  font-style: var(--text-large-light-font-style);
  font-weight: var(--text-large-light-font-weight);
  letter-spacing: var(--text-large-light-letter-spacing);
  line-height: var(--text-large-light-line-height);
  margin-right: -133.00px;
  margin-top: -1.00px;
  position: relative;
  width: 643px;
}

.publications-desktop .link-text-6 {
  color: var(--semantic-link-primary);
  font-family: var(--text-large-light-font-family);
  font-size: var(--text-large-light-font-size);
  font-style: var(--text-large-light-font-style);
  font-weight: var(--text-large-light-font-weight);
  letter-spacing: var(--text-large-light-letter-spacing);
  line-height: var(--text-large-light-line-height);
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.publications-desktop .link-text-7 {
  color: var(--semantic-link-primary);
  font-family: var(--text-large-light-font-family);
  font-size: var(--text-large-light-font-size);
  font-style: var(--text-large-light-font-style);
  font-weight: var(--text-large-light-font-weight);
  height: 74px;
  letter-spacing: var(--text-large-light-letter-spacing);
  line-height: var(--text-large-light-line-height);
  margin-bottom: -6.00px;
  margin-top: -1.00px;
  position: relative;
  width: 501px;
}

.publications-desktop .areas {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  padding: 112px 64px;
  position: relative;
  width: 100%;
}

.publications-desktop .section-title {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 768px;
}

.publications-desktop .tagline-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.publications-desktop .tagline {
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-tagline-font-family);
  font-size: var(--heading-desktop-tagline-font-size);
  font-style: var(--heading-desktop-tagline-font-style);
  font-weight: var(--heading-desktop-tagline-font-weight);
  letter-spacing: var(--heading-desktop-tagline-letter-spacing);
  line-height: var(--heading-desktop-tagline-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.publications-desktop .content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.publications-desktop .heading {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-h2-font-family);
  font-size: var(--heading-desktop-h2-font-size);
  font-style: var(--heading-desktop-h2-font-style);
  font-weight: var(--heading-desktop-h2-font-weight);
  letter-spacing: var(--heading-desktop-h2-letter-spacing);
  line-height: var(--heading-desktop-h2-line-height);
  margin-top: -1.00px;
  position: relative;
}

.publications-desktop .text {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-medium-normal-font-family);
  font-size: var(--text-medium-normal-font-size);
  font-style: var(--text-medium-normal-font-style);
  font-weight: var(--text-medium-normal-font-weight);
  letter-spacing: var(--text-medium-normal-letter-spacing);
  line-height: var(--text-medium-normal-line-height);
  position: relative;
}

.publications-desktop .row-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 64px;
  position: relative;
  width: 100%;
}

.publications-desktop .row {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 48px;
  position: relative;
  width: 100%;
}

.publications-desktop .div-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  position: relative;
}

.publications-desktop .icon {
  height: 48px !important;
  position: relative !important;
  width: 48px !important;
}

.publications-desktop .heading-2 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-h4-font-family);
  font-size: var(--heading-desktop-h4-font-size);
  font-style: var(--heading-desktop-h4-font-style);
  font-weight: var(--heading-desktop-h4-font-weight);
  letter-spacing: var(--heading-desktop-h4-letter-spacing);
  line-height: var(--heading-desktop-h4-line-height);
  position: relative;
}

.publications-desktop .text-2 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
}

.publications-desktop .actions {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.publications-desktop .button-instance {
  flex: 0 0 auto !important;
  margin-bottom: -1.00px !important;
  margin-left: -1.00px !important;
  margin-top: -1.00px !important;
  overflow: hidden !important;
}

.publications-desktop .button-2 {
  flex: 0 0 auto !important;
}

.publications-desktop .CTA {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  padding: 112px 64px;
  position: relative;
  width: 100%;
}

.publications-desktop .container {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 80px;
  position: relative;
  width: 100%;
}

.publications-desktop .column {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  position: relative;
}

.publications-desktop .text-3 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-medium-normal-font-family);
  font-size: var(--text-medium-normal-font-size);
  font-style: var(--text-medium-normal-font-style);
  font-weight: var(--text-medium-normal-font-weight);
  letter-spacing: var(--text-medium-normal-letter-spacing);
  line-height: var(--text-medium-normal-line-height);
  margin-top: -1.00px;
  position: relative;
}

.publications-desktop .actions-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.publications-desktop .button-3 {
  margin-left: -1.00px !important;
  margin-top: -1.00px !important;
}

.publications-desktop .button-4 {
  flex: 0 0 auto !important;
  margin-bottom: -2.00px !important;
  margin-left: -2.00px !important;
  margin-top: -2.00px !important;
}

.publications-desktop .button-5 {
  flex: 0 0 auto !important;
  margin-bottom: -1.00px !important;
  margin-right: -1.00px !important;
  margin-top: -1.00px !important;
}

.publications-desktop .footer {
  align-items: center;
  align-self: stretch;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  padding: 80px 64px;
  position: relative;
  width: 100%;
}

.publications-desktop .content-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
  width: 100%;
}

.publications-desktop .logo-instance {
  height: 53px !important;
  position: relative !important;
  width: 123px !important;
}

.publications-desktop .links {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.publications-desktop .text-wrapper-4 {
  color: var(--semantic-link-primary);
  font-family: var(--text-small-semi-bold-font-family);
  font-size: var(--text-small-semi-bold-font-size);
  font-style: var(--text-small-semi-bold-font-style);
  font-weight: var(--text-small-semi-bold-font-weight);
  letter-spacing: var(--text-small-semi-bold-letter-spacing);
  line-height: var(--text-small-semi-bold-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.publications-desktop .social-links {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 12px;
  justify-content: flex-end;
  position: relative;
}

.publications-desktop .credits {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.publications-desktop .divider {
  align-self: stretch;
  background-color: var(--semantic-border-primary);
  border: 1px solid;
  border-color: var(--semantic-border-primary);
  height: 1px;
  position: relative;
  width: 100%;
}

.publications-desktop .div-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.publications-desktop .element-relume-all {
  color: var(--semantic-text-primary);
  font-family: var(--text-small-normal-font-family);
  font-size: var(--text-small-normal-font-size);
  font-style: var(--text-small-normal-font-style);
  font-weight: var(--text-small-normal-font-weight);
  letter-spacing: var(--text-small-normal-letter-spacing);
  line-height: var(--text-small-normal-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.publications-desktop .text-wrapper-5 {
  color: var(--semantic-link-primary);
  font-family: var(--text-small-link-font-family);
  font-size: var(--text-small-link-font-size);
  font-style: var(--text-small-link-font-style);
  font-weight: var(--text-small-link-font-weight);
  letter-spacing: var(--text-small-link-letter-spacing);
  line-height: var(--text-small-link-line-height);
  margin-top: -1.00px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}
