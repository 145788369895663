.about-desktop {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
}

.about-desktop .navbar {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.about-desktop .navbar-JR-1 {
  align-self: stretch !important;
  width: 100% !important;
}

.about-desktop .icon-instance-node {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.about-desktop .div-2 {
  align-items: flex-start;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  gap: 80px;
  padding: 112px 64px;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.about-desktop .content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 80px;
  height: 497px;
  position: relative;
  width: 100%;
  height: auto;
}

.about-desktop .div-3 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  position: relative;
  max-width: 50%;
}

.about-desktop .tagline-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.about-desktop .tagline {
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-tagline-font-family);
  font-size: var(--heading-desktop-tagline-font-size);
  font-style: var(--heading-desktop-tagline-font-style);
  font-weight: var(--heading-desktop-tagline-font-weight);
  letter-spacing: var(--heading-desktop-tagline-letter-spacing);
  line-height: var(--heading-desktop-tagline-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.about-desktop .short-heading-here {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-h1-font-family);
  font-size: var(--heading-desktop-h1-font-size);
  font-style: var(--heading-desktop-h1-font-style);
  font-weight: var(--heading-desktop-h1-font-weight);
  letter-spacing: var(--heading-desktop-h1-letter-spacing);
  line-height: var(--heading-desktop-h1-line-height);
  position: relative;
}

.about-desktop .column {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 450px;
  position: relative;
  width: 824px;
  margin-top: 0;
  align-self: flex-start;
}

.about-desktop .content-text {
  color: var(--semantic-text-primary);
  font-family: var(--text-large-light-font-family);
  font-size: var(--text-large-light-font-size);
  font-style: var(--text-large-light-font-style);
  font-weight: var(--text-large-light-font-weight);
  letter-spacing: var(--text-large-light-letter-spacing);
  line-height: var(--text-large-light-line-height);
  position: relative;
  width: 790px;
  align-self: flex-start;
  padding-top: 100px;
}

.about-desktop .span {
  font-family: var(--text-large-light-font-family);
  font-size: var(--text-large-light-font-size);
  font-style: var(--text-large-light-font-style);
  font-weight: var(--text-large-light-font-weight);
  letter-spacing: var(--text-large-light-letter-spacing);
  line-height: var(--text-large-light-line-height);
}

.about-desktop .actions {
  flex: 0 0 auto;
  margin-bottom: -80.00px;
  position: relative;
}

.about-desktop .div-4 {
  align-items: flex-start;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  padding: 112px 64px;
  position: relative;
  width: 100%;
}

.about-desktop .div-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 80px;
  position: relative;
  width: 100%;
}

.about-desktop .heading {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-h2-font-family);
  font-size: var(--heading-desktop-h2-font-size);
  font-style: var(--heading-desktop-h2-font-style);
  font-weight: var(--heading-desktop-h2-font-weight);
  letter-spacing: var(--heading-desktop-h2-letter-spacing);
  line-height: var(--heading-desktop-h2-line-height);
  position: relative;
}

.about-desktop .div-6 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  position: relative;
}

.about-desktop .text-suspendisse {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: "Roboto", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 27px;
  margin-top: -1.00px;
  position: relative;
}

.about-desktop .actions-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.about-desktop
  .style-secondary-small-false-alternate-false-icon-position-no-icon {
  flex: 0 0 auto !important;
  margin-bottom: -1.00px !important;
  margin-left: -1.00px !important;
  margin-right: -1.00px !important;
  margin-top: -1.00px !important;
}

.about-desktop .design-component-instance-node-2 {
  text-decoration: underline !important;
}

.about-desktop .main-image-top {
  align-self: stretch;
  height: 738px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.about-desktop .heading-2 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: "Roboto", Helvetica;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 48.0px;
  margin-top: -1.00px;
  position: relative;
}

.about-desktop .div-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  position: relative;
}

.about-desktop .p {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-medium-normal-font-family);
  font-size: var(--text-medium-normal-font-size);
  font-style: var(--text-medium-normal-font-style);
  font-weight: var(--text-medium-normal-font-weight);
  letter-spacing: var(--text-medium-normal-letter-spacing);
  line-height: var(--text-medium-normal-line-height);
  margin-top: -1.00px;
  position: relative;
}

.about-desktop .row-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 64px;
  position: relative;
  width: 100%;
}

.about-desktop .row {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 48px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.about-desktop .div-7 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.about-desktop .icon-relume-1 {
  height: 48px !important;
  position: relative !important;
  width: 48px !important;
}

.about-desktop .heading-3 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-h5-font-family);
  font-size: var(--heading-desktop-h5-font-size);
  font-style: var(--heading-desktop-h5-font-style);
  font-weight: var(--heading-desktop-h5-font-weight);
  letter-spacing: var(--heading-desktop-h5-letter-spacing);
  line-height: var(--heading-desktop-h5-line-height);
  position: relative;
}

.about-desktop .text {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.about-desktop .action {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.about-desktop .button-instance {
  flex: 0 0 auto !important;
}

.about-desktop .heading-4 {
  color: var(--semantic-text-primary);
  font-family: "Roboto", Helvetica;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 48.0px;
  margin-top: -1.00px;
  position: relative;
  width: 768px;
}

.about-desktop .container {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 80px;
  position: relative;
  width: 100%;
}

.about-desktop .content-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 475px;
}

.about-desktop .content-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.about-desktop .div-8 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.about-desktop .heading-5 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-h2-font-family);
  font-size: var(--heading-desktop-h2-font-size);
  font-style: var(--heading-desktop-h2-font-style);
  font-weight: var(--heading-desktop-h2-font-weight);
  letter-spacing: var(--heading-desktop-h2-letter-spacing);
  line-height: var(--heading-desktop-h2-line-height);
  margin-top: -1.00px;
  position: relative;
}

.about-desktop .text-2 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: "Roboto", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 27px;
  position: relative;
}

.about-desktop .text-seattle-fire {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: "Roboto", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 27px;
  position: relative;
  text-align: center;  /* Added to center text */
  max-width: 800px;    /* Added to control text width */
  margin: 0 auto;      /* Added to center the text block */
}




.about-desktop .icon-relume-10 {
  height: 30px !important;
  position: relative !important;
  width: 30px !important;
}

.about-desktop .text-wrapper-4 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-h6-font-family);
  font-size: var(--heading-desktop-h6-font-size);
  font-style: var(--heading-desktop-h6-font-style);
  font-weight: var(--heading-desktop-h6-font-weight);
  letter-spacing: var(--heading-desktop-h6-letter-spacing);
  line-height: var(--heading-desktop-h6-line-height);
  position: relative;
}

.about-desktop .text-3 {
  align-self: stretch;
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.about-desktop .button-3 {
  margin-left: -1.00px !important;
  margin-top: -1.00px !important;
  text-decoration: none !important;
}

.about-desktop .button-4 {
  flex: 0 0 auto !important;
  margin-bottom: -2.00px !important;
  margin-left: -2.00px !important;
  margin-right: -2.00px !important;
  margin-top: -2.00px !important;
}

.about-desktop .gardenvr {
  background-color: #000000;
  height: 651px;
  position: relative;
  width: 746px;
}

.about-desktop .GPT {
  align-items: flex-start;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex-direction: column;
  gap: 80px;
  height: 806px;
  padding: 112px 64px;
  position: relative;
  width: 100%;
}

.about-desktop .container-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 80px;
  margin-bottom: -58.00px;
  position: relative;
  width: 100%;
}

.about-desktop .Teams {
  align-items: flex-start;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex-direction: column;
  gap: 80px;
  height: auto;
  padding: 112px 64px;
  position: relative;
  width: 100%;
}

.about-desktop .container-teams {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 80px;
  margin-bottom: -58.00px;
  position: relative;
  width: 100%;
}

.about-desktop .img {
  flex: 1;
  flex-grow: 1;
  height: 640px;
  object-fit: cover;
  position: relative;
}

.about-desktop .heading-6 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-h3-font-family);
  font-size: var(--heading-desktop-h3-font-size);
  font-style: var(--heading-desktop-h3-font-style);
  font-weight: var(--heading-desktop-h3-font-weight);
  letter-spacing: var(--heading-desktop-h3-letter-spacing);
  line-height: var(--heading-desktop-h3-line-height);
  margin-top: -1.00px;
  position: relative;
}

.about-desktop .text-wrapper-5 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-h6-font-family);
  font-size: var(--heading-desktop-h6-font-size);
  font-style: var(--heading-desktop-h6-font-style);
  font-weight: var(--heading-desktop-h6-font-weight);
  letter-spacing: var(--heading-desktop-h6-letter-spacing);
  line-height: var(--heading-desktop-h6-line-height);
  margin-top: -1.00px;
  position: relative;
}

.about-desktop .button-5 {
  flex: 0 0 auto !important;
  margin-bottom: -2.00px !important;
  margin-left: -2.00px !important;
}

.about-desktop .seattle-fire {
  align-items: center;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex-direction: column;
  gap: 80px;
  height: auto;
  overflow: hidden;
  padding: 112px 36px;
  position: relative;
  width: 100%;
}

.about-desktop .seattle-fire-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 27px;
  height: 994px;
  margin-bottom: -112.00px;
  position: relative;
  width: 1368px;
}

.about-desktop .heading-7 {
  color: var(--semantic-text-primary);
  font-family: "Roboto", Helvetica;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 48.0px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.about-desktop .layout {
  background-color: var(--semantic-background-color-primary-duplicate);
  background-image: url(../../../static/img/VR_Seattle_Fire.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 722px;
  position: relative;
  width: 100%;
}

.about-desktop .co-design {
  align-items: center;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex-direction: column;
  gap: 80px;
  height: 1015px;
  justify-content: center;
  padding: 123px 64px 112px 43px;
  position: relative;
  width: 100%;
}

.about-desktop .content-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  justify-content: center;
  margin-bottom: -28.00px;
  margin-top: -28.00px;
  position: relative;
  width: 100%;
}

.about-desktop .content-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 782px;
  position: relative;
  width: 100%;
}

.about-desktop .placeholder-image-2 {
  flex: 1;
  flex-grow: 1;
  object-fit: cover;
  position: relative;
  width: 1310px;
}

.about-desktop .sea-level {
  align-items: center;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex-direction: column;
  gap: 36px;
  height: 976px;
  overflow: hidden;
  padding: 54px 36px 112px;
  position: relative;
  width: 100%;
}

.about-desktop .discover {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 196px;
  position: relative;
  width: 100%;
}

.about-desktop .heading-8 {
  align-self: stretch;
  color: var(--primitive-color-brand-espresso);
  font-family: var(--heading-mobile-h3-font-family);
  font-size: var(--heading-mobile-h3-font-size);
  font-style: var(--heading-mobile-h3-font-style);
  font-weight: var(--heading-mobile-h3-font-weight);
  letter-spacing: var(--heading-mobile-h3-letter-spacing);
  line-height: var(--heading-mobile-h3-line-height);
  margin-top: -1.00px;
  position: relative;
}

.about-desktop .text-4 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
}

.about-desktop .layout-2 {
  align-items: center;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex-direction: column;
  gap: 48px;
  height: 798px;
  margin-bottom: -112.00px;
  padding: 35px 0px 100px;
  position: relative;
  width: 1371px;
}

.about-desktop .list-item-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 11px;
  margin-bottom: -77.00px;
  position: relative;
  width: 100%;
}

.about-desktop .list-item {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 740px;
  position: relative;
  width: 100%;
}

.about-desktop .content-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 31px;
  height: 697px;
  position: relative;
  width: 100%;
}

.about-desktop .column-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 38px;
  height: 467px;
  overflow: hidden;
  position: relative;
  width: 1371px;
}

.about-desktop .placeholder-image-3 {
  height: 467px;
  object-fit: cover;
  position: relative;
  width: 1371px;
}

.about-desktop .content-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 296px;
  position: relative;
  width: 100%;
}

.about-desktop .heading-9 {
  align-self: stretch;
  color: var(--primitive-color-brand-espresso);
  font-family: var(--heading-desktop-h4-font-family);
  font-size: var(--heading-desktop-h4-font-size);
  font-style: var(--heading-desktop-h4-font-style);
  font-weight: var(--heading-desktop-h4-font-weight);
  height: 150px;
  letter-spacing: var(--heading-desktop-h4-letter-spacing);
  line-height: var(--heading-desktop-h4-line-height);
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  text-decoration: underline;
}

.about-desktop .text-5 {
  align-self: stretch;
  color: #000000;
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
  text-align: center;
}

.about-desktop .heading-10 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h5-font-family);
  font-size: var(--heading-mobile-h5-font-size);
  font-style: var(--heading-mobile-h5-font-style);
  font-weight: var(--heading-mobile-h5-font-weight);
  letter-spacing: var(--heading-mobile-h5-letter-spacing);
  line-height: var(--heading-mobile-h5-line-height);
  position: relative;
}

.about-desktop .container-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
  width: 100%;
}

.about-desktop .row-3 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 768px;
}

.about-desktop .text-6 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-medium-normal-font-family);
  font-size: var(--text-medium-normal-font-size);
  font-style: var(--text-medium-normal-font-style);
  font-weight: var(--text-medium-normal-font-weight);
  letter-spacing: var(--text-medium-normal-letter-spacing);
  line-height: var(--text-medium-normal-line-height);
  position: relative;
}

.about-desktop .button-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.about-desktop .button-6 {
  background-color: var(--primitive-color-brand-carrot) !important;
  border-color: var(--primitive-color-brand-carrot) !important;
  flex: 0 0 auto !important;
  margin-bottom: -2.00px !important;
  margin-left: -2.00px !important;
  margin-right: -2.00px !important;
  margin-top: -2.00px !important;
}

.about-desktop .button-7 {
  color: var(--semantic-text-primary) !important;
  margin-left: -1.00px !important;
  margin-top: -1.00px !important;
}

.about-desktop .text-wrapper-7 {
  color: var(--semantic-text-primary) !important;
  margin-left: -1.00px !important;
  margin-top: -1.00px !important;
  text-decoration: underline !important;
}

.about-desktop .footer {
  align-items: center;
  align-self: stretch;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  padding: 5px 20px 5px 20px;
  position: relative;
  width: 100%;
  height: auto;
}

.about-desktop .company-logo {
  height: 36px !important;
  position: relative !important;
  width: 84px !important;
}

.about-desktop .links {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.about-desktop .text-wrapper-8 {
  color: var(--semantic-link-primary);
  font-family: var(--text-small-semi-bold-font-family);
  font-size: var(--text-small-semi-bold-font-size);
  font-style: var(--text-small-semi-bold-font-style);
  font-weight: var(--text-small-semi-bold-font-weight);
  letter-spacing: var(--text-small-semi-bold-letter-spacing);
  line-height: var(--text-small-semi-bold-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.about-desktop .text-wrapper-8 a {
  color: var(--primitive-color-brand-parsley) !important;
  text-decoration: none;
  font-family: var(--text-small-semi-bold-font-family);
  font-size: var(--text-small-semi-bold-font-size);
  font-style: var(--text-small-semi-bold-font-style);
  font-weight: var(--text-small-semi-bold-font-weight);
  letter-spacing: var(--text-small-semi-bold-letter-spacing);
  line-height: var(--text-small-semi-bold-line-height);
}

.about-desktop .text-wrapper-8 a:hover {
  text-decoration: underline;  /* Optional: adds underline on hover */
}

.about-desktop .social-links {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 12px;
  justify-content: flex-end;
  position: relative;
}

.about-desktop .credits {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.about-desktop .divider {
  align-self: stretch;
  background-color: var(--semantic-border-primary);
  border: 1px solid;
  border-color: var(--semantic-border-primary);
  height: 1px;
  position: relative;
  width: 100%;
}

.about-desktop .row-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.about-desktop .text-wrapper-9 {
  color: var(--semantic-text-primary);
  font-family: var(--text-small-normal-font-family);
  font-size: var(--text-small-normal-font-size);
  font-style: var(--text-small-normal-font-style);
  font-weight: var(--text-small-normal-font-weight);
  letter-spacing: var(--text-small-normal-letter-spacing);
  line-height: var(--text-small-normal-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.about-desktop .text-wrapper-10 {
  color: var(--semantic-link-primary);
  font-family: var(--text-small-link-font-family);
  font-size: var(--text-small-link-font-size);
  font-style: var(--text-small-link-font-style);
  font-weight: var(--text-small-link-font-weight);
  letter-spacing: var(--text-small-link-letter-spacing);
  line-height: var(--text-small-link-line-height);
  margin-top: -1.00px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

