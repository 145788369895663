.menu-with-x {
  align-items: flex-start;
  display: flex;
  height: 81px;
  position: relative;
  width: 393px;
  background-color: var(--semantic-background-color-secondary);
}

.menu-with-x .header {
  align-items: center;
  align-self: stretch;
  background-color: var(---primitive-color-brand-champagne);
  display: flex;
  gap: 0;
  justify-content: center;
  position: relative;
  width: 395px;
}

.menu-with-x .icon-menu {
  background-image: url(../../../static/img/icon-x.svg);
  background-size: 100% 100%;
  height: 81px;
  position: relative;
  width: 81px;
  cursor: pointer;
  padding: 0;
  display: block;
  background-color: var(--primitive-color-brand-champagne);
  border: none;
  margin: 0;
}

.menu-with-x .masthead {
  align-items: center;
  background-color: var(--primitive-color-brand-champagne);
  display: flex;
  gap: 0;
  height: 81px;
  justify-content: center;
  padding: 0;
  position: relative;
  width: 100%;
  border: none;
  align-items: center;
  justify-content: center;
}

.menu-with-x .text-wrapper {
  color: var(--primitive-color-brand-espresso-duplicate);
  font-family: var(--heading-mobile-h6-font-family);
  font-size: var(--heading-mobile-h6-font-size);
  font-style: var(--heading-mobile-h6-font-style);
  font-weight: var(--heading-mobile-h6-font-weight);
  height: 47px;
  letter-spacing: var(--heading-mobile-h6-letter-spacing);
  line-height: var(--heading-mobile-h6-line-height);
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: 265px;
}

.text-center {
  text-align: center;
}

