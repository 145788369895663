.publications-mobile {
  align-items: center;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex-direction: column;
  gap: 42px;
  position: relative;
  width: 393px;
}

.publications-mobile .header {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--primitive-color-brand-champagne-duplicate);
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 393px;
}

.publications-mobile .div {
  align-items: center;
  align-self: stretch;
  background-color: var(--primitive-color-brand-champagne-duplicate);
  border: 1px solid;
  border-color: var(--primitive-color-brand-purple-duplicate);
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: -1.00px;
  margin-left: -1.00px;
  margin-right: -1.00px;
  margin-top: -1.00px;
  padding: 0px 0px 0px 10px;
  position: relative;
  width: 395px;
}

.publications-mobile .icon-menu {
  height: 51px !important;
  position: relative !important;
  width: 51px !important;
}

.publications-mobile .masthead {
  height: 81px;
  position: relative;
  width: 320px;
}

.publications-mobile .articles {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 335px;
}

.publications-mobile .article {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 4px;
  padding: 15px 0px;
  position: relative;
  width: 100%;
}

.publications-mobile .article-icon {
  height: 18px !important;
  position: relative !important;
  width: 18px !important;
}

.publications-mobile .title {
  color: var(--semantic-link-primary-duplicate);
  font-family: var(--text-small-light-font-family);
  font-size: var(--text-small-light-font-size);
  font-style: var(--text-small-light-font-style);
  font-weight: var(--text-small-light-font-weight);
  letter-spacing: var(--text-small-light-letter-spacing);
  line-height: var(--text-small-light-line-height);
  margin-right: -36.00px;
  margin-top: -1.00px;
  position: relative;
  text-decoration: underline;
  width: 349px;
}

.publications-mobile .article-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 4px;
  padding: 15px 0px;
  position: relative;
  width: 224px;
}

.publications-mobile .link-text {
  color: var(--semantic-link-primary-duplicate);
  font-family: var(--text-small-light-font-family);
  font-size: var(--text-small-light-font-size);
  font-style: var(--text-small-light-font-style);
  font-weight: var(--text-small-light-font-weight);
  letter-spacing: var(--text-small-light-letter-spacing);
  line-height: var(--text-small-light-line-height);
  margin-right: -22.00px;
  margin-top: -1.00px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.publications-mobile .article-3 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 4px;
  padding: 15px 0px;
  position: relative;
  width: 335px;
}

.publications-mobile .p {
  color: var(--semantic-link-primary-duplicate);
  font-family: var(--text-small-light-font-family);
  font-size: var(--text-small-light-font-size);
  font-style: var(--text-small-light-font-style);
  font-weight: var(--text-small-light-font-weight);
  letter-spacing: var(--text-small-light-letter-spacing);
  line-height: var(--text-small-light-line-height);
  margin-top: -1.00px;
  position: relative;
  text-decoration: underline;
  width: 303px;
}

.publications-mobile .article-4 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 4px;
  padding: 15px 0px;
  position: relative;
  width: 325px;
}

.publications-mobile .link-text-2 {
  color: var(--semantic-link-primary-duplicate);
  font-family: var(--text-small-light-font-family);
  font-size: var(--text-small-light-font-size);
  font-style: var(--text-small-light-font-style);
  font-weight: var(--text-small-light-font-weight);
  letter-spacing: var(--text-small-light-letter-spacing);
  line-height: var(--text-small-light-line-height);
  margin-top: -1.00px;
  position: relative;
  text-decoration: underline;
  width: 302px;
}

.publications-mobile .link-text-3 {
  color: var(--semantic-link-primary-duplicate);
  font-family: var(--text-small-light-font-family);
  font-size: var(--text-small-light-font-size);
  font-style: var(--text-small-light-font-style);
  font-weight: var(--text-small-light-font-weight);
  letter-spacing: var(--text-small-light-letter-spacing);
  line-height: var(--text-small-light-line-height);
  margin-top: -1.00px;
  position: relative;
  text-decoration: underline;
  width: 306px;
}

.publications-mobile .main-title {
  color: var(--semantic-text-primary);
  font-family: "Ofelia Text-Light", Helvetica;
  font-size: 50px;
  font-weight: 300;
  height: 194px;
  letter-spacing: -0.50px;
  line-height: 60.0px;
  position: relative;
  text-align: center;
  width: 318px;
}

.publications-mobile .contact-us {
  display: flex !important;
  flex: 0 0 auto !important;
  width: 209px !important;
}

.publications-mobile .icon-relume-1 {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.publications-mobile .subtitle {
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h1-font-family);
  font-size: var(--heading-mobile-h1-font-size);
  font-style: var(--heading-mobile-h1-font-style);
  font-weight: var(--heading-mobile-h1-font-weight);
  height: 188px;
  letter-spacing: var(--heading-mobile-h1-letter-spacing);
  line-height: var(--heading-mobile-h1-line-height);
  position: relative;
  text-align: center;
  width: 263px;
}

.publications-mobile .description {
  color: var(--semantic-text-primary);
  font-family: var(--text-large-light-font-family);
  font-size: var(--text-large-light-font-size);
  font-style: var(--text-large-light-font-style);
  font-weight: var(--text-large-light-font-weight);
  height: 231px;
  letter-spacing: var(--text-large-light-letter-spacing);
  line-height: var(--text-large-light-line-height);
  position: relative;
  text-align: center;
  width: 306px;
}
