.services-desktop {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.services-desktop .navbar {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.services-desktop .navbar-JR-1 {
  align-self: stretch !important;
  width: 100% !important;
}

.services-desktop .icon-instance-node {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.services-desktop .header {
  align-items: flex-start;
  align-self: stretch;
  background-color: #00000080;
  background-image: url(../../../static/img/header-50.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 80px;
  height: 330px;
  padding: 86px 64px 68px;
  position: relative;
  width: 100%;
}

.services-desktop .container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 223px;
  position: relative;
  width: 1217px;
}

.services-desktop .section-title {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 250px;
  margin-bottom: -27.00px;
  margin-right: -118.00px;
  position: relative;
  width: 1335px;
}

.services-desktop .tagline-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.services-desktop .tagline {
  color: var(--semantic-text-alternate);
  font-family: var(--heading-desktop-tagline-font-family);
  font-size: var(--heading-desktop-tagline-font-size);
  font-style: var(--heading-desktop-tagline-font-style);
  font-weight: var(--heading-desktop-tagline-font-weight);
  letter-spacing: var(--heading-desktop-tagline-letter-spacing);
  line-height: var(--heading-desktop-tagline-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.services-desktop .div-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.services-desktop .text-wrapper-4 {
  align-self: stretch;
  color: var(--primitive-color-brand-champagne);
  font-family: var(--heading-desktop-h1-font-family);
  font-size: var(--heading-desktop-h1-font-size);
  font-style: var(--heading-desktop-h1-font-style);
  font-weight: var(--heading-desktop-h1-font-weight);
  letter-spacing: var(--heading-desktop-h1-letter-spacing);
  line-height: var(--heading-desktop-h1-line-height);
  margin-top: -1.00px;
  position: relative;
}

.services-desktop .p {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-medium-normal-font-family);
  font-size: var(--text-medium-normal-font-size);
  font-style: var(--text-medium-normal-font-style);
  font-weight: var(--text-medium-normal-font-weight);
  letter-spacing: var(--text-medium-normal-letter-spacing);
  line-height: var(--text-medium-normal-line-height);
  position: relative;
}

.services-desktop .contact-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  justify-content: center;
  position: relative;
}

.services-desktop .contact {
  background-color: var(--primitive-color-brand-carrot) !important;
  border: unset !important;
  border-color: unset !important;
  height: 40px !important;
  width: 119px !important;
}

.services-desktop .contact-2 {
  color: var(--semantic-text-primary) !important;
  margin-bottom: unset !important;
  margin-left: unset !important;
  margin-right: unset !important;
  margin-top: unset !important;
}

.services-desktop .actions {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  margin-bottom: -107.00px;
  position: relative;
}

.about-desktop .button-6 {
  background-color: var(--primitive-color-brand-carrot) !important;
  border-color: var(--primitive-color-brand-carrot) !important;
  flex: 0 0 auto !important;
  margin-bottom: -2.00px !important;
  margin-left: -2.00px !important;
  margin-right: -2.00px !important;
  margin-top: -2.00px !important;
}

.services-desktop .button-instance {
  margin-left: -1.00px !important;
  margin-top: -1.00px !important;
}

.services-desktop .button-2 {
  flex: 0 0 auto !important;
  margin-bottom: -2.00px !important;
  margin-left: -2.00px !important;
  margin-top: -2.00px !important;
}

.services-desktop .button-3 {
  flex: 0 0 auto !important;
  margin-bottom: -1.00px !important;
  margin-right: -1.00px !important;
  margin-top: -1.00px !important;
}

.services-desktop .container-wrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  padding: 112px 64px;
  position: relative;
  width: 100%;
}

.services-desktop .container-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 80px;
  position: relative;
  width: 100%;
}

.services-desktop .div-3 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  position: relative;
}


.services-desktop .content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.services-desktop .div-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.services-desktop .tagline-2 {
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-tagline-font-family);
  font-size: var(--heading-desktop-tagline-font-size);
  font-style: var(--heading-desktop-tagline-font-style);
  font-weight: var(--heading-desktop-tagline-font-weight);
  letter-spacing: var(--heading-desktop-tagline-letter-spacing);
  line-height: var(--heading-desktop-tagline-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.services-desktop .text-wrapper-5 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-h2-font-family);
  font-size: var(--heading-desktop-h2-font-size);
  font-style: var(--heading-desktop-h2-font-style);
  font-weight: var(--heading-desktop-h2-font-weight);
  letter-spacing: var(--heading-desktop-h2-letter-spacing);
  line-height: var(--heading-desktop-h2-line-height);
  margin-top: -1.00px;
  position: relative;
}

.services-desktop .text {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-medium-normal-font-family);
  font-size: var(--text-medium-normal-font-size);
  font-style: var(--text-medium-normal-font-style);
  font-weight: var(--text-medium-normal-font-weight);
  letter-spacing: var(--text-medium-normal-letter-spacing);
  line-height: var(--text-medium-normal-line-height);
  position: relative;
}

.services-desktop .row {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  padding: 8px 0px;
  position: relative;
  width: 100%;
}

.services-desktop .list-item {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  position: relative;
}

.services-desktop .text-wrapper-6 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-h6-font-family);
  font-size: var(--heading-desktop-h6-font-size);
  font-style: var(--heading-desktop-h6-font-style);
  font-weight: var(--heading-desktop-h6-font-weight);
  letter-spacing: var(--heading-desktop-h6-letter-spacing);
  line-height: var(--heading-desktop-h6-line-height);
  margin-top: -1.00px;
  position: relative;
}

.services-desktop .text-2 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
}

.services-desktop .actions-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}


.services-desktop .button-5 {
  flex: 0 0 auto !important;
}

.services-desktop .img {
  flex: 1;
  flex-grow: 1;
  height: 640px;
  object-fit: cover;
  position: relative;
}

.services-desktop .div-5 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  padding: 112px 64px;
  position: relative;
  width: 100%;
}

.services-desktop .section-title-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 768px;
}

.services-desktop .row-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 64px;
  position: relative;
  width: 100%;
}

.services-desktop .row-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 48px;
  position: relative;
  width: 100%;
}

.services-desktop .div-6 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  position: relative;
}

.services-desktop .icon-relume-7 {
  height: 48px !important;
  position: relative !important;
  width: 48px !important;
}

.services-desktop .heading {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-h4-font-family);
  font-size: var(--heading-desktop-h4-font-size);
  font-style: var(--heading-desktop-h4-font-style);
  font-weight: var(--heading-desktop-h4-font-weight);
  letter-spacing: var(--heading-desktop-h4-letter-spacing);
  line-height: var(--heading-desktop-h4-line-height);
  position: relative;
}

.services-desktop .heading-classes {
  align-self: stretch;
  color: var(--primitive-color-brand-parsley);
  font-family: var(--heading-desktop-h4-font-family);
  font-size: var(--heading-desktop-h4-font-size);
  font-style: var(--heading-desktop-h4-font-style);
  font-weight: var(--heading-desktop-h4-font-weight);
  letter-spacing: var(--heading-desktop-h4-letter-spacing);
  line-height: var(--heading-desktop-h4-line-height);
  position: relative;
}


.services-desktop .heading-2 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-h3-font-family);
  font-size: var(--heading-desktop-h3-font-size);
  font-style: var(--heading-desktop-h3-font-style);
  font-weight: var(--heading-desktop-h3-font-weight);
  letter-spacing: var(--heading-desktop-h3-letter-spacing);
  line-height: var(--heading-desktop-h3-line-height);
  margin-top: -1.00px;
  position: relative;
}

.services-desktop .text-wrapper-7 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-h6-font-family);
  font-size: var(--heading-desktop-h6-font-size);
  font-style: var(--heading-desktop-h6-font-style);
  font-weight: var(--heading-desktop-h6-font-weight);
  letter-spacing: var(--heading-desktop-h6-letter-spacing);
  line-height: var(--heading-desktop-h6-line-height);
  position: relative;
}

.services-desktop .placeholder-image {
  background-color: #000000;
  flex: 1;
  flex-grow: 1;
  height: 640px;
  position: relative;
}

.services-desktop .icon-relume-1 {
  height: 80px !important;
  position: relative !important;
  width: 80px !important;
}

.services-desktop .list-item-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  position: relative;
}

.services-desktop .placeholder-image-2 {
  flex: 1;
  flex-grow: 1;
  height: 640px;
  position: relative;
}

.services-desktop .container-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 80px;
  position: relative;
  width: 100%;
}

.services-desktop .text-3 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-medium-normal-font-family);
  font-size: var(--text-medium-normal-font-size);
  font-style: var(--text-medium-normal-font-style);
  font-weight: var(--text-medium-normal-font-weight);
  letter-spacing: var(--text-medium-normal-letter-spacing);
  line-height: var(--text-medium-normal-line-height);
  margin-top: -1.00px;
  position: relative;
}

.services-desktop .actions-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.services-desktop .footer {
  align-items: center;
  align-self: stretch;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  padding: 80px 64px;
  position: relative;
  width: 100%;
}

.services-desktop .content-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
  width: 100%;
}

.services-desktop .color-dark {
  height: 53px !important;
  position: relative !important;
  width: 123px !important;
}

.services-desktop .links {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.services-desktop .text-wrapper-8 {
  color: var(--semantic-link-primary);
  font-family: var(--text-small-semi-bold-font-family);
  font-size: var(--text-small-semi-bold-font-size);
  font-style: var(--text-small-semi-bold-font-style);
  font-weight: var(--text-small-semi-bold-font-weight);
  letter-spacing: var(--text-small-semi-bold-letter-spacing);
  line-height: var(--text-small-semi-bold-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.services-desktop .social-links {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 12px;
  justify-content: flex-end;
  position: relative;
}

.services-desktop .credits {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.services-desktop .divider {
  align-self: stretch;
  background-color: var(--semantic-border-primary);
  border: 1px solid;
  border-color: var(--semantic-border-primary);
  height: 1px;
  position: relative;
  width: 100%;
}

.services-desktop .div-7 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.services-desktop .element-relume-all {
  color: var(--semantic-text-primary);
  font-family: var(--text-small-normal-font-family);
  font-size: var(--text-small-normal-font-size);
  font-style: var(--text-small-normal-font-style);
  font-weight: var(--text-small-normal-font-weight);
  letter-spacing: var(--text-small-normal-letter-spacing);
  line-height: var(--text-small-normal-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.services-desktop .text-wrapper-9 {
  color: var(--semantic-link-primary);
  font-family: var(--text-small-link-font-family);
  font-size: var(--text-small-link-font-size);
  font-style: var(--text-small-link-font-style);
  font-weight: var(--text-small-link-font-weight);
  letter-spacing: var(--text-small-link-letter-spacing);
  line-height: var(--text-small-link-line-height);
  margin-top: -1.00px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.services-desktop .button-4 {
  flex: 0 0 auto !important;
  margin-bottom: -1.00px !important;
  margin-left: -1.00px !important;
  margin-top: -1.00px !important;
  overflow: hidden !important;
}

.services-desktop .button-4 a {
  color: var( --primitive-color-neutral-neutral-lightest) !important;
  text-decoration: none;
  font-family: var(--text-medium-semi-bold-font-family);
  font-size: var(--text-medium-semi-bold-font-size);
  font-weight: var(--text-medium-semi-bold-font-weight);
  letter-spacing: var(--text-medium-semi-bold-letter-spacing);
  line-height: var(--text-medium-semi-bold-line-height);
}

.services-desktop .button-4 a:hover {
  color: white !important;
  text-decoration: none;
}
