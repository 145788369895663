.contact-desktop {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.contact-desktop .navbar {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.contact-desktop .navbar-JR-1 {
  align-self: stretch !important;
  width: 100% !important;
}

.contact-desktop .icon-instance-node {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.contact-desktop .contact-wrapper {
  align-items: center;
  align-self: stretch;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 112px 64px;
  position: relative;
  width: 100%;
}

.contact-desktop .section-title {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 768px;
}

.contact-desktop .tagline-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.contact-desktop .text-wrapper-6 {
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-tagline-font-family);
  font-size: var(--heading-desktop-tagline-font-size);
  font-style: var(--heading-desktop-tagline-font-style);
  font-weight: var(--heading-desktop-tagline-font-weight);
  letter-spacing: var(--heading-desktop-tagline-letter-spacing);
  line-height: var(--heading-desktop-tagline-line-height);
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.contact-desktop .content {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.contact-desktop .heading {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-h2-font-family);
  font-size: var(--heading-desktop-h2-font-size);
  font-style: var(--heading-desktop-h2-font-style);
  font-weight: var(--heading-desktop-h2-font-weight);
  letter-spacing: var(--heading-desktop-h2-letter-spacing);
  line-height: var(--heading-desktop-h2-line-height);
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.contact-desktop .text {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-medium-normal-font-family);
  font-size: var(--text-medium-normal-font-size);
  font-style: var(--text-medium-normal-font-style);
  font-weight: var(--text-medium-normal-font-weight);
  letter-spacing: var(--text-medium-normal-letter-spacing);
  line-height: var(--text-medium-normal-line-height);
  position: relative;
  text-align: center;
}

.contact-desktop .form {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 768px;
}

.contact-desktop .inputs {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
  width: 100%;
}

.contact-desktop .input {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  position: relative;
}

.contact-desktop .div-4 {
  align-self: stretch;
  background: transparent;
  border: none;
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  margin-top: -1.00px;
  padding: 0;
  position: relative;
}

.contact-desktop .type-default {
  align-self: stretch;
  border: 1px solid;
  border-color: var(--primitive-color-neutral-black);
  flex: 0 0 auto;
  margin-bottom: -1.00px;
  margin-left: -1.00px;
  margin-right: -1.00px;
  position: relative;
  width: 100%;
}

.contact-desktop .input-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.contact-desktop .text-wrapper-7 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  margin-top: -1.00px;
  position: relative;
}

.contact-desktop .select-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  margin-bottom: -1.00px !important;
  margin-left: -1.00px !important;
  margin-right: -1.00px !important;
  width: 100% !important;
}

.contact-desktop .design-component-instance-node-2 {
  font-family: "Roboto", Helvetica !important;
  font-size: 16px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
}

.contact-desktop .radios {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0px;
  position: relative;
  width: 100%;
}

.contact-desktop .text-wrapper-8 {
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact-desktop .content-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  position: relative;
  width: 100%;
}

.contact-desktop .row {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 24px;
  height: 24px;
  position: relative;
  width: 100%;
}

.contact-desktop .selected-false {
  display: flex !important;
  flex: 1 !important;
  flex-grow: 1 !important;
}

.contact-desktop .alternate-false {
  align-self: stretch !important;
  margin-bottom: -1.00px !important;
  margin-left: -1.00px !important;
  margin-right: -1.00px !important;
  width: 100% !important;
}

.contact-desktop .text-area-instance {
  left: 764px !important;
}

.contact-desktop .alternate-false-2 {
  left: 760px !important;
}

.contact-desktop .checkbox-instance {
  align-self: stretch !important;
  display: flex !important;
  height: 37px !important;
  padding: 0px 0px 16px !important;
  width: 100% !important;
}

.contact-desktop .selected-false-2 {
  font-family: "Roboto", Helvetica !important;
  font-size: 14px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 21px !important;
}

.contact-desktop .style-primary-small {
  font-family: "Roboto", Helvetica !important;
  font-size: 16px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  margin-left: -1.00px !important;
  margin-top: -1.00px !important;
}

.contact-desktop .button-instance {
  display: flex !important;
  flex: 0 0 auto !important;
  margin-bottom: -2.00px !important;
  width: 103px !important;
}

.contact-desktop .testimonial {
  align-items: flex-start;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  overflow: hidden;
  padding: 64px 20px 64px 204px;
  position: relative;
  width: 100%;
}

.contact-desktop .section-title-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.contact-desktop .heading-2 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h2-font-family);
  font-size: var(--heading-mobile-h2-font-size);
  font-style: var(--heading-mobile-h2-font-style);
  font-weight: var(--heading-mobile-h2-font-weight);
  letter-spacing: var(--heading-mobile-h2-letter-spacing);
  line-height: var(--heading-mobile-h2-line-height);
  margin-top: -1.00px;
  position: relative;
}

.contact-desktop .p {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
}

.contact-desktop .content-3 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  margin-right: -112.00px;
  position: relative;
  width: 1656px;
}

.contact-desktop .column {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.contact-desktop .stars {
  flex: 0 0 auto;
  position: relative;
}

.contact-desktop .quote {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h6-font-family);
  font-size: var(--heading-mobile-h6-font-size);
  font-style: var(--heading-mobile-h6-font-style);
  font-weight: var(--heading-mobile-h6-font-weight);
  letter-spacing: var(--heading-mobile-h6-letter-spacing);
  line-height: var(--heading-mobile-h6-line-height);
  position: relative;
}

.contact-desktop .avatar {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.contact-desktop .avatar-image {
  height: 56px;
  object-fit: cover;
  position: relative;
  width: 56px;
}

.contact-desktop .avatar-content {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.contact-desktop .text-2 {
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-semi-bold-font-family);
  font-size: var(--text-regular-semi-bold-font-size);
  font-style: var(--text-regular-semi-bold-font-style);
  font-weight: var(--text-regular-semi-bold-font-weight);
  letter-spacing: var(--text-regular-semi-bold-letter-spacing);
  line-height: var(--text-regular-semi-bold-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact-desktop .text-3 {
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact-desktop .CTA {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  padding: 112px 64px;
  position: relative;
  width: 100%;
}

.contact-desktop .container {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 80px;
  position: relative;
  width: 100%;
}

.contact-desktop .div-5 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  position: relative;
}

.contact-desktop .heading-3 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-h2-font-family);
  font-size: var(--heading-desktop-h2-font-size);
  font-style: var(--heading-desktop-h2-font-style);
  font-weight: var(--heading-desktop-h2-font-weight);
  letter-spacing: var(--heading-desktop-h2-letter-spacing);
  line-height: var(--heading-desktop-h2-line-height);
  margin-top: -1.00px;
  position: relative;
}

.contact-desktop .column-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  position: relative;
}

.contact-desktop .text-4 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-medium-normal-font-family);
  font-size: var(--text-medium-normal-font-size);
  font-style: var(--text-medium-normal-font-style);
  font-weight: var(--text-medium-normal-font-weight);
  letter-spacing: var(--text-medium-normal-letter-spacing);
  line-height: var(--text-medium-normal-line-height);
  margin-top: -1.00px;
  position: relative;
}

.contact-desktop .actions {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.contact-desktop .button-2 {
  flex: 0 0 auto !important;
  margin-bottom: -2.00px !important;
  margin-left: -2.00px !important;
  margin-top: -2.00px !important;
}

.contact-desktop .button-3 {
  margin-left: -1.00px !important;
  margin-top: -1.00px !important;
}

.contact-desktop .button-4 {
  flex: 0 0 auto !important;
  margin-bottom: -1.00px !important;
  margin-right: -1.00px !important;
  margin-top: -1.00px !important;
}

.contact-desktop .footer {
  align-items: center;
  align-self: stretch;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  padding: 80px 64px;
  position: relative;
  width: 100%;
}

.contact-desktop .content-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
  width: 100%;
}

.contact-desktop .logo-instance {
  height: 53px !important;
  position: relative !important;
  width: 123px !important;
}

.contact-desktop .links {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.contact-desktop .text-wrapper-9 {
  color: var(--semantic-link-primary);
  font-family: var(--text-small-semi-bold-font-family);
  font-size: var(--text-small-semi-bold-font-size);
  font-style: var(--text-small-semi-bold-font-style);
  font-weight: var(--text-small-semi-bold-font-weight);
  letter-spacing: var(--text-small-semi-bold-letter-spacing);
  line-height: var(--text-small-semi-bold-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact-desktop .social-links {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 12px;
  justify-content: flex-end;
  position: relative;
}

.contact-desktop .credits {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.contact-desktop .divider {
  align-self: stretch;
  background-color: var(--semantic-border-primary);
  border: 1px solid;
  border-color: var(--semantic-border-primary);
  height: 1px;
  position: relative;
  width: 100%;
}

.contact-desktop .div-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.contact-desktop .element-relume-all {
  color: var(--semantic-text-primary);
  font-family: var(--text-small-normal-font-family);
  font-size: var(--text-small-normal-font-size);
  font-style: var(--text-small-normal-font-style);
  font-weight: var(--text-small-normal-font-weight);
  letter-spacing: var(--text-small-normal-letter-spacing);
  line-height: var(--text-small-normal-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact-desktop .text-wrapper-10 {
  color: var(--semantic-link-primary);
  font-family: var(--text-small-link-font-family);
  font-size: var(--text-small-link-font-size);
  font-style: var(--text-small-link-font-style);
  font-weight: var(--text-small-link-font-weight);
  letter-spacing: var(--text-small-link-letter-spacing);
  line-height: var(--text-small-link-line-height);
  margin-top: -1.00px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}
