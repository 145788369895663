.navbar-JR {
  align-items: center;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex-direction: row;
  height: 112px;
  justify-content: space-between;
  padding: 0px 63px 0px 64px;
  position: relative;
  width: 1768px;
}

.navbar-JR .menu {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 61px;
  justify-content: flex-end;
  position: relative;
  width: 914px;
}

.navbar-JR .design-component-instance-node {
  flex: 0 0 auto !important;
}

.navbar-JR .contact-us {
  display: flex !important;
  margin-bottom: -1.00px !important;
  margin-right: -1.00px !important;
  margin-top: -1.00px !important;
  width: 209px !important;
}

.navbar-JR .icon-relume-2 {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.navbar-JR .company-name {
  font-family: var(--heading-desktop-h3-font-family);
  font-size: var(--heading-desktop-h3-font-size);
  font-style: var(--heading-desktop-h3-font-style);
  font-weight: var(--heading-desktop-h3-font-weight);
  letter-spacing: var(--heading-desktop-h3-letter-spacing);
  line-height: var(--heading-desktop-h3-line-height);
  display: block;
  margin-top: 0;
  color: var(--primitive-color-brand-parsley);
  text-decoration: none;
}

.navbar-JR .company-name:hover {
  color: var(--primitive-color-brand-carrot);
}
