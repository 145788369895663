.contact-mobile {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 393px;
  background-color: var(--semantic-background-color-primary);
}

.contact-mobile .header {
  align-items: flex-start;
  background-color: var(--primitive-color-brand-champagne-duplicate);
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 393px;
}

.contact-mobile .header-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--primitive-color-brand-champagne-duplicate);
  border: 1px solid;
  border-color: var(--primitive-color-brand-purple-duplicate);
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 0px 0px 0px 10px;
  position: relative;
}

.contact-mobile .icon-menu {
  height: 51px !important;
  position: relative !important;
  width: 51px !important;
}

.contact-mobile .masthead {
  height: 81px;
  position: relative;
  width: 320px;
}

.contact-mobile .contact {
  align-items: center;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 64px 20px;
  position: relative;
  width: 393px;
}

.contact-mobile .section-title {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.contact-mobile .tagline-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.contact-mobile .tagline {
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-tagline-font-family);
  font-size: var(--heading-desktop-tagline-font-size);
  font-style: var(--heading-desktop-tagline-font-style);
  font-weight: var(--heading-desktop-tagline-font-weight);
  letter-spacing: var(--heading-desktop-tagline-letter-spacing);
  line-height: var(--heading-desktop-tagline-line-height);
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  background-color: var(--semantic-background-color-primary);
}

.contact-mobile .content {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 393px;
  background-color: var(--semantic-background-color-secondary);
}

.contact-mobile .heading {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h2-font-family);
  font-size: var(--heading-mobile-h2-font-size);
  font-style: var(--heading-mobile-h2-font-style);
  font-weight: var(--heading-mobile-h2-font-weight);
  letter-spacing: var(--heading-mobile-h2-letter-spacing);
  line-height: var(--heading-mobile-h2-line-height);
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.contact-mobile .text {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
  text-align: center;
}

.contact-mobile .form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 0 20px;
  background-color: var(--semantic-background-color-secondary);
}

.contact-mobile .input {
  width: 100%;
}

.contact-mobile .div-4 {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--primitive-color-brand-purple-duplicate);
  border-radius: 4px;
}

.contact-mobile .input-2 textarea {
  width: 100%;
  min-height: 150px;
  padding: 10px;
  border: 1px solid var(--primitive-color-brand-purple-duplicate);
  border-radius: 4px;
}

.contact-mobile .button-instance {
  background-color: #5C191C !important;
  color: white !important;
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  z-index: 1;
  margin-top: 20px;
  font-size: 16px;
}

.contact-mobile .footer {
  width: 100%;
  padding: 32px 20px;
  background-color: var(--primitive-color-brand-champagne-duplicate);
  border-top: 1px solid var(--primitive-color-brand-purple-duplicate);
}

.contact-mobile .footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.contact-mobile .social-links {
  display: flex;
  gap: 16px;
}

.contact-mobile .social-icon {
  color: var(--primitive-color-brand-purple-duplicate);
  cursor: pointer;
}

.contact-mobile .copyright {
  color: var(--primitive-color-brand-purple-duplicate);
  font-size: 14px;
  text-align: center;
}

.contact-mobile .checkbox-wrapper {
  margin: 20px 0;
}

.contact-mobile .checkbox-wrapper input[type="checkbox"] {
  border-color: var(--primitive-color-brand-purple-duplicate);
}

.contact-mobile .text-wrapper-6 {
  color: var(--primitive-color-brand-purple-duplicate);
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
}

.contact-mobile .heading {
  color: var(--primitive-color-brand-purple-duplicate);
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 12px;
}

.contact-mobile .text {
  color: var(--primitive-color-brand-purple-duplicate);
  text-align: center;
  font-size: 16px;
  margin-bottom: 32px;
}

.contact-mobile .testimonial {
  align-items: flex-start;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 64px 20px;
  position: relative;
  width: 393px;
}

.contact-mobile .section-title-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.contact-mobile .heading-2 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h2-font-family);
  font-size: var(--heading-mobile-h2-font-size);
  font-style: var(--heading-mobile-h2-font-style);
  font-weight: var(--heading-mobile-h2-font-weight);
  letter-spacing: var(--heading-mobile-h2-letter-spacing);
  line-height: var(--heading-mobile-h2-line-height);
  margin-top: -1.00px;
  position: relative;
}

.contact-mobile .p {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
}

.contact-mobile .content-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  position: relative;
  width: 100%;
}

.contact-mobile .stars {
  flex: 0 0 auto;
  position: relative;
}

.contact-mobile .quote {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h6-font-family);
  font-size: var(--heading-mobile-h6-font-size);
  font-style: var(--heading-mobile-h6-font-style);
  font-weight: var(--heading-mobile-h6-font-weight);
  letter-spacing: var(--heading-mobile-h6-letter-spacing);
  line-height: var(--heading-mobile-h6-line-height);
  position: relative;
}

.contact-mobile .avatar {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.contact-mobile .avatar-image {
  height: 56px;
  object-fit: cover;
  position: relative;
  width: 56px;
}

.contact-mobile .avatar-content {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.contact-mobile .text-2 {
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-semi-bold-font-family);
  font-size: var(--text-regular-semi-bold-font-size);
  font-style: var(--text-regular-semi-bold-font-style);
  font-weight: var(--text-regular-semi-bold-font-weight);
  letter-spacing: var(--text-regular-semi-bold-letter-spacing);
  line-height: var(--text-regular-semi-bold-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact-mobile .text-3 {
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact-mobile .logo-section {
  margin-bottom: 16px;
}

.contact-mobile .div-5 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.contact-mobile .logo-instance {
  height: 53px !important;
  position: relative !important;
  width: 123px !important;
}

.contact-mobile .link-one {
  align-self: stretch;
  color: var(--semantic-link-primary);
  font-family: var(--text-small-semi-bold-font-family);
  font-size: var(--text-small-semi-bold-font-size);
  font-style: var(--text-small-semi-bold-font-style);
  font-weight: var(--text-small-semi-bold-font-weight);
  letter-spacing: var(--text-small-semi-bold-letter-spacing);
  line-height: var(--text-small-semi-bold-line-height);
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.contact-mobile .text-wrapper-3 {
  align-self: stretch;
  color: var(--semantic-link-primary);
  font-family: var(--text-small-semi-bold-font-family);
  font-size: var(--text-small-semi-bold-font-size);
  font-style: var(--text-small-semi-bold-font-style);
  font-weight: var(--text-small-semi-bold-font-weight);
  letter-spacing: var(--text-small-semi-bold-letter-spacing);
  line-height: var(--text-small-semi-bold-line-height);
  position: relative;
  text-align: center;
}

.contact-mobile .credits {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  padding: 0px 0px 16px;
  position: relative;
  width: 100%;
}

.contact-mobile .divider {
  align-self: stretch;
  background-color: var(--semantic-border-primary);
  border: 1px solid;
  border-color: var(--semantic-border-primary);
  height: 1px;
  position: relative;
  width: 100%;
}

.contact-mobile .row-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.contact-mobile .footer-links {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.contact-mobile .text-wrapper-4 {
  color: var(--semantic-link-primary);
  font-family: var(--text-small-link-font-family);
  font-size: var(--text-small-link-font-size);
  font-style: var(--text-small-link-font-style);
  font-weight: var(--text-small-link-font-weight);
  letter-spacing: var(--text-small-link-letter-spacing);
  line-height: var(--text-small-link-line-height);
  margin-top: -1.00px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.contact-mobile .text-wrapper-5 {
  color: var(--semantic-link-primary);
  font-family: var(--text-small-link-font-family);
  font-size: var(--text-small-link-font-size);
  font-style: var(--text-small-link-font-style);
  font-weight: var(--text-small-link-font-weight);
  letter-spacing: var(--text-small-link-letter-spacing);
  line-height: var(--text-small-link-line-height);
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.contact-mobile .element-relume-all {
  color: var(--semantic-text-primary);
  font-family: var(--text-small-normal-font-family);
  font-size: var(--text-small-normal-font-size);
  font-style: var(--text-small-normal-font-style);
  font-weight: var(--text-small-normal-font-weight);
  letter-spacing: var(--text-small-normal-letter-spacing);
  line-height: var(--text-small-normal-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
