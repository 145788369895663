.menu-mobile {
  align-items: center;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex-direction: column;
  gap: 56px;
  position: relative;
  width: 393px;
}


.menu-mobile .menu-header {
  background-image: url(../../../static/img/icon-x-2.svg) !important;
}

.menu-mobile .menu-with-x-instance {
  font-family: "Roboto", Helvetica !important;
  font-size: 18px !important;
  font-style: unset !important;
  font-weight: 700 !important;
  letter-spacing: 0 !important;
  line-height: 25.2px !important;
 
}

.menu-mobile .menu-item-instance {
  flex: 0 0 auto !important;
  color: var(--semantic-link-primary) !important;
  font-size: 12px !important;
}

.menu-mobile .menu-item-instance:hover {
  color: var(--primitive-color-brand-carrot) !important;
}

.menu-mobile .design-component-instance-node {
  font-family: "Roboto", Helvetica !important;
  font-size: 28px !important;
  font-style: unset !important;
  font-weight: 700 !important;
  letter-spacing: 0 !important;
  line-height: 47.6px !important;
}

.menu-mobile .section-title {
  align-self: stretch;
  color: var(--primitive-color-brand-espresso-duplicate);
  font-family: "Roboto", Helvetica;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 57.6px;
  position: relative;
  text-align: center;
}

.menu-mobile .main-title {
  color: var(--semantic-text-primary);
  font-family: "Ofelia Text-Light", Helvetica;
  font-size: 50px;
  font-weight: 300;
  height: 203px;
  letter-spacing: -0.50px;
  line-height: 60.0px;
  position: relative;
  text-align: center;
  width: 334px;
}

.menu-mobile .subtitle {
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h1-font-family);
  font-size: var(--heading-mobile-h1-font-size);
  font-style: var(--heading-mobile-h1-font-style);
  font-weight: var(--heading-mobile-h1-font-weight);
  height: 188px;
  letter-spacing: var(--heading-mobile-h1-letter-spacing);
  line-height: var(--heading-mobile-h1-line-height);
  position: relative;
  text-align: center;
  width: 263px;
}

.menu-mobile .description {
  color: var(--semantic-text-primary);
  font-family: var(--text-large-light-font-family);
  font-size: var(--text-large-light-font-size);
  font-style: var(--text-large-light-font-style);
  font-weight: var(--text-large-light-font-weight);
  height: 231px;
  letter-spacing: var(--text-large-light-letter-spacing);
  line-height: var(--text-large-light-line-height);
  position: relative;
  text-align: center;
  width: 306px;
}

.menu-mobile .header {
  align-items: center;
  align-self: stretch;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: -1.00px;
  margin-left: -1.00px;
  margin-right: -1.00px;
  margin-top: -1.00px;
  padding: 0px 0px 0px 10px;
  position: relative;
  width: 395px;
}
