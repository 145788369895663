.about-mobile {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 393px;
  background-color: var(--semantic-background-color-primary);
}

.about-mobile .header {
  align-items: flex-start;
  background-color: var(--primitive-color-brand-champagne-duplicate);
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.about-mobile .header-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--primitive-color-brand-champagne-duplicate);
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: -1.00px;
  margin-left: -1.00px;
  margin-right: -1.00px;
  margin-top: -1.00px;
  padding: 0px 0px 0px 10px;
  position: relative;
  width: 395px;
}

.about-mobile .icon-menu {
  height: 51px !important;
  position: relative !important;
  width: 51px !important;
}

.about-mobile .masthead {
  height: 81px;
  position: relative;
  width: 320px;
}

.about-mobile .div-wrapper {
  align-items: flex-start;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 64px 20px;
  position: relative;
  width: 393px;
}

.about-mobile .content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.about-mobile .div-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.about-mobile .tagline-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.about-mobile .tagline {
  color: var(--semantic-text-primary);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.about-mobile .short-heading-here {
  align-self: stretch;
  color: var(--primitive-color-brand-espresso);
  font-family: var(--heading-mobile-h1-font-family);
  font-size: var(--heading-mobile-h1-font-size);
  font-style: var(--heading-mobile-h1-font-style);
  font-weight: var(--heading-mobile-h1-font-weight);
  letter-spacing: var(--heading-mobile-h1-letter-spacing);
  line-height: var(--heading-mobile-h1-line-height);
  position: relative;
}

.about-mobile .div-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.about-mobile .lorem-ipsum-dolor {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-large-light-font-family);
  font-size: var(--text-large-light-font-size);
  font-style: var(--text-large-light-font-style);
  font-weight: var(--text-large-light-font-weight);
  letter-spacing: var(--text-large-light-letter-spacing);
  line-height: var(--text-large-light-line-height);
  margin-top: -1.00px;
  position: relative;
}

.about-mobile .actions {
  align-items: center;
  display: flex;
  gap: 16px;
  height: 42px;
  justify-content: center;
  position: relative;
  width: 123px;
}

.about-mobile .contact {
  background-color: var(--primitive-color-brand-carrot) !important;
  border: unset !important;
  border-color: unset !important;
  height: 40px !important;
  width: 119px !important;
  text-decoration: underline !important;
}

.about-mobile .button-instance {
  color: var(--semantic-text-primary) !important;
  margin-bottom: unset !important;
  margin-left: unset !important;
  margin-right: unset !important;
  margin-top: unset !important;
}

.about-mobile .design-component-instance-node {
  margin-left: -1.00px !important;
  margin-top: -1.00px !important;
}

.about-mobile .layout {
  align-items: flex-start;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 64px 20px;
  position: relative;
  width: 393px;
}

.about-mobile .heading {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h2-font-family);
  font-size: var(--heading-mobile-h2-font-size);
  font-style: var(--heading-mobile-h2-font-style);
  font-weight: var(--heading-mobile-h2-font-weight);
  letter-spacing: var(--heading-mobile-h2-letter-spacing);
  line-height: var(--heading-mobile-h2-line-height);
  position: relative;
}

.about-mobile .p {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
}

.about-mobile .actions-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.about-mobile
  .style-secondary-small-false-alternate-false-icon-position-no-icon {
  flex: 0 0 auto !important;
  margin-bottom: -1.00px !important;
  margin-left: -1.00px !important;
  margin-right: -1.00px !important;
  margin-top: -1.00px !important;
}

.about-mobile .style-secondary-instance {
  text-decoration: underline !important;
}

.about-mobile .placeholder-image {
  align-self: stretch;
  height: 188px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.about-mobile .container-wrapper {
  align-items: flex-start;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 48px 20px;
  position: relative;
  width: 393px;
}

.about-mobile .container {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  position: relative;
  width: 100%;
}

.about-mobile .tagline-2 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.about-mobile .heading-2 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h2-font-family);
  font-size: var(--heading-mobile-h2-font-size);
  font-style: var(--heading-mobile-h2-font-style);
  font-weight: var(--heading-mobile-h2-font-weight);
  letter-spacing: var(--heading-mobile-h2-letter-spacing);
  line-height: var(--heading-mobile-h2-line-height);
  margin-top: -1.00px;
  position: relative;
}

.about-mobile .text {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.about-mobile .list {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  padding: 8px 0px;
  position: relative;
  width: 100%;
}

.about-mobile .icon-relume-5 {
  height: 48px !important;
  position: relative !important;
  width: 48px !important;
}

.about-mobile .text-wrapper-2 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h6-font-family);
  font-size: var(--heading-mobile-h6-font-size);
  font-style: var(--heading-mobile-h6-font-style);
  font-weight: var(--heading-mobile-h6-font-weight);
  letter-spacing: var(--heading-mobile-h6-letter-spacing);
  line-height: var(--heading-mobile-h6-line-height);
  position: relative;
}

.about-mobile
  .style-secondary-small-false-alternate-false-icon-position-no-icon-instance {
  flex: 0 0 auto !important;
  margin-bottom: -1.00px !important;
  margin-left: -1.00px !important;
  margin-top: -1.00px !important;
}

.about-mobile .design-component-instance-node-2 {
  flex: 0 0 auto !important;
}

.about-mobile .icon-instance-node {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.about-mobile .gardenvr {
  background-color: #000000;
  height: 305px;
  position: relative;
  width: 350px;
}

.about-mobile .layout-2 {
  align-items: flex-start;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 48px 20px;
  position: relative;
  width: 393px;
}

.about-mobile .heading-3 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: "Roboto", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 38.4px;
  margin-top: -1.00px;
  position: relative;
}

.about-mobile .text-wrapper-3 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h6-font-family);
  font-size: var(--heading-mobile-h6-font-size);
  font-style: var(--heading-mobile-h6-font-style);
  font-weight: var(--heading-mobile-h6-font-weight);
  letter-spacing: var(--heading-mobile-h6-letter-spacing);
  line-height: var(--heading-mobile-h6-line-height);
  margin-top: -1.00px;
  position: relative;
}

.about-mobile .img {
  height: 162px;
  margin-right: -11.00px;
  object-fit: cover;
  position: relative;
  width: 364px;
}

.about-mobile .placeholder-image-2 {
  align-self: stretch;
  height: 348px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.about-mobile .testimonial {
  align-items: center;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 64px 20px;
  position: relative;
  width: 393px;
}

.about-mobile .content-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.about-mobile .container-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.about-mobile .column {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 335px;
}

.about-mobile .div-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.about-mobile .quote {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28.0px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.about-mobile .avatar {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.about-mobile .avatar-image {
  height: 56px;
  object-fit: cover;
  position: relative;
  width: 56px;
}

.about-mobile .avatar-content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.about-mobile .text-2 {
  align-self: stretch;
  color: #000000;
  font-family: var(--text-regular-semi-bold-font-family);
  font-size: var(--text-regular-semi-bold-font-size);
  font-style: var(--text-regular-semi-bold-font-style);
  font-weight: var(--text-regular-semi-bold-font-weight);
  letter-spacing: var(--text-regular-semi-bold-letter-spacing);
  line-height: var(--text-regular-semi-bold-line-height);
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.about-mobile .text-3 {
  align-self: stretch;
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-align: center;
}

.about-mobile .logo-2-color-dark {
  height: 48px !important;
  left: unset !important;
  margin-left: -3072.50px !important;
  margin-top: -3691.00px !important;
  position: relative !important;
  top: unset !important;
  width: 120px !important;
}

.about-mobile .quote-2 {
  align-self: stretch;
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28.0px;
  position: relative;
  text-align: center;
}

.about-mobile .avatar-2 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 300px;
}

.about-mobile .avatar-image-2 {
  height: 56px;
  margin-left: -3075.50px;
  margin-top: -3927.00px;
  object-fit: cover;
  position: relative;
  width: 56px;
}

.about-mobile .slider-dots {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 9px;
  padding: 10px;
  position: relative;
}

.about-mobile .dot {
  background-color: #000000;
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 8px;
}

.about-mobile .dot-2 {
  background-color: var(--primitive-color-neutral-neutral-lighter-duplicate);
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 8px;
}

.about-mobile .nourishing-minds {
  align-items: center;
  align-self: stretch;
  background-color: var(--primitive-color-brand-champagne);
  display: flex;
  flex-direction: column;
  gap: 22px;
  height: 789px;
  padding: 21px 20px 28px;
  position: relative;
  width: 100%;
}

.about-mobile .NM-teens {
  height: 339.55px;
  margin-left: -20.00px;
  margin-right: -20.00px;
  position: relative;
  width: 353px;
}

.about-mobile .section-title {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 327px;
  position: relative;
  width: 100%;
}

.about-mobile .heading-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.about-mobile .heading-4 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h3-font-family);
  font-size: var(--heading-mobile-h3-font-size);
  font-style: var(--heading-mobile-h3-font-style);
  font-weight: var(--heading-mobile-h3-font-weight);
  letter-spacing: var(--heading-mobile-h3-letter-spacing);
  line-height: var(--heading-mobile-h3-line-height);
  margin-top: -1.00px;
  position: relative;
}

.about-mobile .text-suspendisse-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  margin-bottom: -27.00px;
  position: relative;
  width: 100%;
}

.about-mobile .sea-level {
  align-items: center;
  align-self: stretch;
  background-color: var(--primitive-color-brand-champagne);
  display: flex;
  flex-direction: column;
  gap: 22px;
  height: 2001px;
  padding: 21px 20px 36px;
  position: relative;
  width: 100%;
}

.about-mobile .row-wrapper {
  align-items: center;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex-direction: column;
  gap: 48px;
  height: 995px;
  margin-left: -19.00px;
  margin-right: -19.00px;
  padding: 35px 0px 64px;
  position: relative;
  width: 351px;
}

.about-mobile .row {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 11px;
  margin-bottom: -47.00px;
  position: relative;
  width: 100%;
}

.about-mobile .heading-5 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h5-font-family);
  font-size: var(--heading-mobile-h5-font-size);
  font-style: var(--heading-mobile-h5-font-style);
  font-weight: var(--heading-mobile-h5-font-weight);
  letter-spacing: var(--heading-mobile-h5-letter-spacing);
  line-height: var(--heading-mobile-h5-line-height);
  margin-top: -1.00px;
  position: relative;
}

.about-mobile .action {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.about-mobile .button-5 {
  color: var(--semantic-text-primary) !important;
  font-family: "Roboto", Helvetica !important;
  font-size: 16px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
}

.about-mobile .list-item {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 740px;
  position: relative;
  width: 100%;
}

.about-mobile .content-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 31px;
  height: 697px;
  position: relative;
  width: 100%;
}

.about-mobile .column-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 38px;
  height: 467px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.about-mobile .placeholder-image-3 {
  align-self: stretch;
  height: 269px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.about-mobile .content-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 296px;
  position: relative;
  width: 100%;
}

.about-mobile .heading-6 {
  align-self: stretch;
  color: var(--primitive-color-brand-espresso);
  font-family: var(--heading-desktop-h4-font-family);
  font-size: var(--heading-desktop-h4-font-size);
  font-style: var(--heading-desktop-h4-font-style);
  font-weight: var(--heading-desktop-h4-font-weight);
  height: 150px;
  letter-spacing: var(--heading-desktop-h4-letter-spacing);
  line-height: var(--heading-desktop-h4-line-height);
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  text-decoration: underline;
}

.about-mobile .heading-7 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h5-font-family);
  font-size: var(--heading-mobile-h5-font-size);
  font-style: var(--heading-mobile-h5-font-style);
  font-weight: var(--heading-mobile-h5-font-weight);
  letter-spacing: var(--heading-mobile-h5-letter-spacing);
  line-height: var(--heading-mobile-h5-line-height);
  position: relative;
}

.about-mobile .button-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 42px;
  margin-bottom: -23.00px;
  position: relative;
  width: 100%;
}

.about-mobile .button-6 {
  font-family: "Roboto", Helvetica !important;
  font-size: 16px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
}

.about-mobile .seattle-fire {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 27px;
  height: 488px;
  position: relative;
  width: 100%;
}

.about-mobile .heading-8 {
  color: var(--semantic-text-primary);
  font-family: "Roboto", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 38.4px;
  margin-left: -21.00px;
  margin-right: -21.00px;
  margin-top: -1.00px;
  position: relative;
  text-decoration: underline;
  width: 355px;
}

.about-mobile .tagline-3 {
  color: var(--semantic-text-primary-duplicate);
  font-family: var(--heading-desktop-tagline-font-family);
  font-size: var(--heading-desktop-tagline-font-size);
  font-style: var(--heading-desktop-tagline-font-style);
  font-weight: var(--heading-desktop-tagline-font-weight);
  letter-spacing: var(--heading-desktop-tagline-letter-spacing);
  line-height: var(--heading-desktop-tagline-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.about-mobile .layout-3 {
  background-color: var(--semantic-background-color-primary-duplicate);
  background-image: url(../../../static/img/VR_Seattle_Fire.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 223px;
  margin-left: -31.00px;
  margin-right: -31.00px;
  position: relative;
  width: 375px;
}

.about-mobile .discover {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  margin-bottom: -19.00px;
  position: relative;
  width: 100%;
}

.about-mobile .heading-9 {
  align-self: stretch;
  color: var(--primitive-color-brand-espresso);
  font-family: var(--heading-mobile-h3-font-family);
  font-size: var(--heading-mobile-h3-font-size);
  font-style: var(--heading-mobile-h3-font-style);
  font-weight: var(--heading-mobile-h3-font-weight);
  letter-spacing: var(--heading-mobile-h3-letter-spacing);
  line-height: var(--heading-mobile-h3-line-height);
  margin-top: -1.00px;
  position: relative;
}

.about-mobile .container-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.about-mobile .button-7 {
  flex: 0 0 auto !important;
  margin-bottom: -2.00px !important;
  margin-left: -2.00px !important;
  margin-top: -2.00px !important;
}

.about-mobile .button-8 {
  flex: 0 0 auto !important;
  margin-bottom: -1.00px !important;
  margin-right: -1.00px !important;
  margin-top: -1.00px !important;
}

.about-mobile .footer {
  align-items: center;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 48px 20px;
  position: relative;
  width: 393px;
}

.about-mobile .content-5 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  position: relative;
  width: 100%;
}

.about-mobile .logo-instance {
  height: 36px !important;
  position: relative !important;
  width: 84px !important;
}

.about-mobile .social-links {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.about-mobile .credits {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  padding: 0px 0px 16px;
  position: relative;
  width: 100%;
}

.about-mobile .divider {
  align-self: stretch;
  background-color: var(--semantic-border-primary);
  border: 1px solid;
  border-color: var(--semantic-border-primary);
  height: 1px;
  position: relative;
  width: 100%;
}

.about-mobile .row-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.about-mobile .footer-links {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.about-mobile .text-wrapper-4 {
  color: var(--semantic-link-primary);
  font-family: var(--text-small-link-font-family);
  font-size: var(--text-small-link-font-size);
  font-style: var(--text-small-link-font-style);
  font-weight: var(--text-small-link-font-weight);
  letter-spacing: var(--text-small-link-letter-spacing);
  line-height: var(--text-small-link-line-height);
  margin-top: -1.00px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.about-mobile .text-wrapper-5 {
  color: var(--semantic-link-primary);
  font-family: var(--text-small-link-font-family);
  font-size: var(--text-small-link-font-size);
  font-style: var(--text-small-link-font-style);
  font-weight: var(--text-small-link-font-weight);
  letter-spacing: var(--text-small-link-letter-spacing);
  line-height: var(--text-small-link-line-height);
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.about-mobile .element-relume-all {
  color: var(--semantic-text-primary);
  font-family: var(--text-small-normal-font-family);
  font-size: var(--text-small-normal-font-size);
  font-style: var(--text-small-normal-font-style);
  font-weight: var(--text-small-normal-font-weight);
  letter-spacing: var(--text-small-normal-letter-spacing);
  line-height: var(--text-small-normal-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
