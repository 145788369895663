.hover-about {
  align-items: center;
  display: inline-flex;
  gap: 4px;
  justify-content: center;
  position: relative;
}

.hover-about .text-wrapper {
  color: var(--semantic-link-primary-duplicate);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.hover-about:hover .text-wrapper {
  color: var(--primitive-color-brand-carrot);
  text-decoration: underline;
}
