.services-mobile {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 393px;
  background-color: var(--semantic-background-color-primary);
}

.services-mobile .header {
  align-items: flex-start;
  background-color: var(--primitive-color-brand-champagne-duplicate);
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.services-mobile .div {
  align-items: center;
  align-self: stretch;
  background-color: var(--primitive-color-brand-champagne-duplicate);
  border-color: var(--primitive-color-brand-purple);
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: -1.00px;
  margin-left: -1.00px;
  margin-right: -1.00px;
  margin-top: -1.00px;
  padding: 0px 0px 0px 10px;
  position: relative;
  width: 395px;
}

.services-mobile .icon-menu {
  height: 51px !important;
  position: relative !important;
  width: 51px !important;
}

.services-mobile .masthead {
  height: 81px;
  position: relative;
  width: 320px;
}

.services-mobile .leading {
  align-items: flex-start;
  background-color: #00000080;
  background-image: url(../../../static/img/leading.png);
  background-position: center;
  background-size: cover;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  padding: 32px 20px;
  position: relative;
  width: 393px;
  height: 200px;
  justify-content: center;
}

.services-mobile .div-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.services-mobile .div-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.services-mobile .tagline-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.services-mobile .tagline {
  color: #ffffff;
  font-family: var(--heading-desktop-tagline-font-family);
  font-size: var(--heading-desktop-tagline-font-size);
  font-style: var(--heading-desktop-tagline-font-style);
  font-weight: var(--heading-desktop-tagline-font-weight);
  letter-spacing: var(--heading-desktop-tagline-letter-spacing);
  line-height: var(--heading-desktop-tagline-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.services-mobile .div-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.services-mobile .title {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--heading-mobile-h1-font-family);
  font-size: var(--heading-mobile-h1-font-size);
  font-style: var(--heading-mobile-h1-font-style);
  font-weight: var(--heading-mobile-h1-font-weight);
  letter-spacing: var(--heading-mobile-h1-letter-spacing);
  line-height: var(--heading-mobile-h1-line-height);
  margin-top: -1.00px;
  position: relative;
}

.services-mobile .description {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
}

.services-mobile .actions {
  all: unset;
  align-items: flex-start;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.services-mobile .button {
  all: unset;
  align-items: center;
  background-color: var(--primitive-color-neutral-white);
  border: 1px solid;
  border-color: var(--primitive-color-neutral-white);
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  margin-bottom: -2.00px;
  margin-left: -2.00px;
  margin-right: -2.00px;
  margin-top: -2.00px;
  padding: 12px 24px;
  position: relative;
}

.services-mobile .button-text {
  all: unset;
  box-sizing: border-box;
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  margin-left: -1.00px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.services-mobile .container-wrapper {
  align-items: flex-start;
  background-color: var(--semantic-background-color-secondary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 64px 20px;
  position: relative;
  width: 393px;
}

.services-mobile .div-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  position: relative;
  width: 100%;
}

.services-mobile .text-wrapper {
  color: var(--semantic-text-primary);
  font-family: var(--heading-desktop-tagline-font-family);
  font-size: var(--heading-desktop-tagline-font-size);
  font-style: var(--heading-desktop-tagline-font-style);
  font-weight: var(--heading-desktop-tagline-font-weight);
  letter-spacing: var(--heading-desktop-tagline-letter-spacing);
  line-height: var(--heading-desktop-tagline-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.services-mobile .text-wrapper-2 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h2-font-family);
  font-size: var(--heading-mobile-h2-font-size);
  font-style: var(--heading-mobile-h2-font-style);
  font-weight: var(--heading-mobile-h2-font-weight);
  letter-spacing: var(--heading-mobile-h2-letter-spacing);
  line-height: var(--heading-mobile-h2-line-height);
  margin-top: -1.00px;
  position: relative;
}

.services-mobile .text {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
}

.services-mobile .list {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  padding: 8px 0px;
  position: relative;
  width: 100%;
}

.services-mobile .text-wrapper-3 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h6-font-family);
  font-size: var(--heading-mobile-h6-font-size);
  font-style: var(--heading-mobile-h6-font-style);
  font-weight: var(--heading-mobile-h6-font-weight);
  letter-spacing: var(--heading-mobile-h6-letter-spacing);
  line-height: var(--heading-mobile-h6-line-height);
  margin-top: -1.00px;
  position: relative;
}

.services-mobile .actions-2 {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.services-mobile .actions-courses {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  margin-bottom: -1.00px;
  margin-left: -1.00px;
  margin-right: -1.00px;
  margin-top: -1.00px;
  color: var(--semantic-text-primary);
}

.services-mobile .inquire-courses {
  background-color: var(--semantic-background-color-primary);
  border: 1px solid;
  border-color: var(--semantic-border-primary);
  border-radius: 10px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding: 4px 16px;
  min-width: 100px;
  text-align: center;
}

.services-mobile .inquire-courses .text-wrapper-2 {
  all: unset;
}

.services-mobile .inquire-courses .inquire-link-text {
  color: var(--primitive-color-brand-espresso) !important;
  font-family: var(--text-medium-light-font-family) !important;
  font-size: var(--text-medium-light-font-size) !important;
  font-style: var(--text-medium-light-font-style) !important;
  font-weight: var(--text-medium-light-font-weight) !important;
  letter-spacing: var(--text-medium-light-letter-spacing) !important;
  line-height: var(--text-medium-light-line-height) !important;
  text-decoration: none !important;
}

.services-mobile .div-wrapper {
  all: unset;
  align-items: center;
  border: 1px solid;
  border-color: var(--primitive-color-neutral-black);
  border-radius: 10px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  margin-bottom: -1.00px;
  margin-left: -1.00px;
  margin-right: -1.00px;
  margin-top: -1.00px;
  overflow: hidden;
  padding: 12px 24px;
  position: relative;
}

.services-mobile .text-wrapper-4 {
  all: unset;
  box-sizing: border-box;
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.services-mobile .placeholder-image {
  align-self: stretch;
  background-color: #000000;
  height: 348px;
  position: relative;
  width: 100%;
}

.services-mobile .div-6 {
  align-items: flex-start;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 64px 20px;
  position: relative;
  width: 393px;
}

.services-mobile .icon {
  height: 48px !important;
  position: relative !important;
  width: 48px !important;
}

.services-mobile .heading-courses {
  font-family: var(--heading-mobile-h5-font-family);
  font-size: var(--heading-mobile-h5-font-size);
  font-style: var(--heading-mobile-h5-font-style);
  font-weight: var(--heading-mobile-h5-font-weight);
  letter-spacing: var(--heading-mobile-h5-letter-spacing);
  line-height: var(--heading-mobile-h5-line-height);
  position: relative;
  margin-top: -1.00px;
  color: var(--primitive-color-brand-parsley);
}
.services-mobile .heading {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h4-font-family);
  font-size: var(--heading-mobile-h4-font-size);
  font-style: var(--heading-mobile-h4-font-style);
  font-weight: var(--heading-mobile-h4-font-weight);
  letter-spacing: var(--heading-mobile-h4-letter-spacing);
  line-height: var(--heading-mobile-h4-line-height);
  position: relative;
}

.services-mobile .p {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h3-font-family);
  font-size: var(--heading-mobile-h3-font-size);
  font-style: var(--heading-mobile-h3-font-style);
  font-weight: var(--heading-mobile-h3-font-weight);
  letter-spacing: var(--heading-mobile-h3-letter-spacing);
  line-height: var(--heading-mobile-h3-line-height);
  margin-top: -1.00px;
  position: relative;
}

.services-mobile .text-2 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-medium-normal-font-family);
  font-size: var(--text-medium-normal-font-size);
  font-style: var(--text-medium-normal-font-style);
  font-weight: var(--text-medium-normal-font-weight);
  letter-spacing: var(--text-medium-normal-letter-spacing);
  line-height: var(--text-medium-normal-line-height);
  position: relative;
}

.services-mobile .img {
  align-self: stretch;
  height: 348px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.services-mobile .curricula {
  align-items: flex-start;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 48px 20px;
  position: relative;
  width: 393px;
}

.services-mobile .text-wrapper-5 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--heading-mobile-h6-font-family);
  font-size: var(--heading-mobile-h6-font-size);
  font-style: var(--heading-mobile-h6-font-style);
  font-weight: var(--heading-mobile-h6-font-weight);
  letter-spacing: var(--heading-mobile-h6-letter-spacing);
  line-height: var(--heading-mobile-h6-line-height);
  position: relative;
}

.services-mobile .icon-4 {
  height: 80px !important;
  position: relative !important;
  width: 80px !important;
}

.services-mobile .photograph-in-color {
  height: 350px;
  object-fit: cover;
  position: relative;
  width: 336px;
}

.services-mobile .placeholder-image-2 {
  height: 340px;
  object-fit: cover;
  position: relative;
  width: 328px;
}

.services-mobile .list-item {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.services-mobile .text-3 {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  margin-top: -1.00px;
  position: relative;
}

.services-mobile .button-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.services-mobile .label-wrapper {
  all: unset;
  align-items: center;
  background-color: var(--primitive-color-neutral-black);
  border: 1px solid;
  border-color: var(--primitive-color-neutral-black);
  border-radius: 10px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  margin-bottom: -2.00px;
  margin-left: -2.00px;
  margin-right: -2.00px;
  margin-top: -2.00px;
  padding: 12px 24px;
  position: relative;
}

.services-mobile .label {
  color: var(--primitive-color-brand-champagne);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  margin-left: -1.00px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.services-mobile .footer {
  align-items: center;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 48px 20px;
  position: relative;
  width: 393px;
}

.services-mobile .content {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  position: relative;
  width: 100%;
}

.services-mobile .div-7 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.services-mobile .logo-instance {
  height: 36px !important;
  position: relative !important;
  width: 84px !important;
}

.services-mobile .link-one {
  align-self: stretch;
  color: var(--semantic-link-primary);
  font-family: var(--text-small-semi-bold-font-family);
  font-size: var(--text-small-semi-bold-font-size);
  font-style: var(--text-small-semi-bold-font-style);
  font-weight: var(--text-small-semi-bold-font-weight);
  letter-spacing: var(--text-small-semi-bold-letter-spacing);
  line-height: var(--text-small-semi-bold-line-height);
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.services-mobile .text-wrapper-6 {
  align-self: stretch;
  color: var(--semantic-link-primary);
  font-family: var(--text-small-semi-bold-font-family);
  font-size: var(--text-small-semi-bold-font-size);
  font-style: var(--text-small-semi-bold-font-style);
  font-weight: var(--text-small-semi-bold-font-weight);
  letter-spacing: var(--text-small-semi-bold-letter-spacing);
  line-height: var(--text-small-semi-bold-line-height);
  position: relative;
  text-align: center;
}

.services-mobile .social-links {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.services-mobile .icon-instance-node {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.services-mobile .credits {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  padding: 0px 0px 16px;
  position: relative;
  width: 100%;
}

.services-mobile .divider {
  align-self: stretch;
  background-color: var(--semantic-border-primary);
  border: 1px solid;
  border-color: var(--semantic-border-primary);
  height: 1px;
  position: relative;
  width: 100%;
}

.services-mobile .row {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.services-mobile .footer-links {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.services-mobile .text-wrapper-7 {
  color: var(--semantic-link-primary);
  font-family: var(--text-small-link-font-family);
  font-size: var(--text-small-link-font-size);
  font-style: var(--text-small-link-font-style);
  font-weight: var(--text-small-link-font-weight);
  letter-spacing: var(--text-small-link-letter-spacing);
  line-height: var(--text-small-link-line-height);
  margin-top: -1.00px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.services-mobile .text-wrapper-8 {
  color: var(--semantic-link-primary);
  font-family: var(--text-small-link-font-family);
  font-size: var(--text-small-link-font-size);
  font-style: var(--text-small-link-font-style);
  font-weight: var(--text-small-link-font-weight);
  letter-spacing: var(--text-small-link-letter-spacing);
  line-height: var(--text-small-link-line-height);
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.services-mobile .element-relume-all {
  color: var(--semantic-text-primary);
  font-family: var(--text-small-normal-font-family);
  font-size: var(--text-small-normal-font-size);
  font-style: var(--text-small-normal-font-style);
  font-weight: var(--text-small-normal-font-weight);
  letter-spacing: var(--text-small-normal-letter-spacing);
  line-height: var(--text-small-normal-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.inquire-link-text {
  color: var(--primitive-color-brand-espresso);
  font-family: var(--text-medium-light-font-family);
  font-size: var(--text-medium-light-font-size);
  font-style: var(--text-medium-light-font-style);
  font-weight: var(--text-medium-light-font-weight);
  letter-spacing: var(--text-medium-light-letter-spacing);
  line-height: var(--text-medium-light-line-height);
  text-decoration: none;
}
