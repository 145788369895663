.links {
  display: flex;
  gap: 32px;
}

.text-wrapper-8 {
  color: #000000;
  font-family: "Inter-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
}

.text-wrapper-8 a {
  color: inherit;
  text-decoration: none;
}
